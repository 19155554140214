@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section5 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include md {
    display: none;
  }
}
.flexRow {
  display: flex;
  width: 74.51%;
  position: relative;
  flex-grow: 1;
  margin: 0px auto;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 440px;
  margin: 92px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  width: 83.18%;
  position: relative;
  min-height: 204px;
  margin: 0px 12.95% 0px 3.86%;
}
.group {
  @include flex-column;
  height: 509px;
  position: relative;
  margin: 295px 0px 0px;
}
.content_box14 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 90.45%;
  position: absolute;
  top: 33px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 9.55% 0px 0%;
}
.flexCol1 {
  @include flex-column;
  width: 70.6%;
  position: relative;
  flex-grow: 1;
  margin: 93px auto 65px;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
}
.icon5 {
  width: 79px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 79px;
  margin: 0px auto;
}
.subtitle {
  margin: 43px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 196px;
}
.subtitle_span0 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 400px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 29px;
  position: relative;
  flex-grow: 1;
  min-width: 29px;
  margin: 43px auto 18px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 193px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 440px;
  margin: 0px 0px 242px;
}
.group1 {
  @include flex-column;
  height: 509px;
  position: relative;
}
.content_box15 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 90.45%;
  position: absolute;
  top: 33px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 9.55% 0px 0%;
}
.flexCol3 {
  @include flex-column;
  width: 65.83%;
  position: relative;
  flex-grow: 1;
  margin: 56.5px auto 6.5px;
}
.flexCol3__item {
  @include flex-column;
  position: relative;
}
.image5 {
  width: 49px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 49px;
  margin: 0px auto;
}
.subtitle1 {
  margin: 35px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
}
.subtitle1_span0 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.hero_title11 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  position: relative;
  min-height: 225px;
  margin: 124px 4px 0px 0px;
}
