@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 54px 54px 54px 54px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/1.15 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 28px/1.32 "Sora", Helvetica, Arial, serif;
$font_type_5: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_6: 700 23px/1.09 "Sora", Helvetica, Arial, serif;
$font_type_7: 600 23px/1.09 "Sora", Helvetica, Arial, serif;
$font_type_8: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_9: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_10: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_11: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_12: 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_13: 400 18px/1.11 "Sora", Helvetica, Arial, serif;
$font_type_14: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_15: 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_16: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_17: 700 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_18: 0.7777777777777778em/1.8 "Sora", Helvetica, Arial, serif;
$font_type_19: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_20: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_21: 0.9em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_22: 700 1em/1.15 "Sora", Helvetica, Arial, serif;
$font_type_23: 1em/1.15 "Sora", Helvetica, Arial, serif;
$font_type_24: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 21159px;
}
.flexCol {
  @include flex-column;
  width: 74.79%;
  position: relative;
  flex-grow: 1;
  margin: 47px 11.11% 289px 14.1%;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_4, 0px);
  position: relative;
  margin: 0px 7px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 55px 39px 0px 0px;
}
.highlights1 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 0px 0px 0px 8px;
}
.flexCol2 {
  @include flex-column;
  width: 95.66%;
  position: relative;
  margin: 21px 4.34% 0px 0%;
}
.flexRow {
  display: flex;
  position: relative;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon1 {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 44px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle3 {
  margin: 9px 0px 0px;
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
}
.subtitle3_span0 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span1 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow1 {
  display: flex;
  position: relative;
  margin: 21px 0px 0px;
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle31 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  margin: 9px 0px 0px;
}
.flexRow2 {
  display: flex;
  position: relative;
  margin: 30px 0px 0px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol3 {
  @include flex-column;
  width: 92.94%;
  position: relative;
  margin: 169px 6.13% 0px 0.93%;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image2 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 6px 0px 14px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle32 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
}
.subtitle32_span0 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle32_span1 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights11 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 271px;
  margin: 49px 0px 0px 25px;
}
.flexCol4 {
  @include flex-column;
  position: relative;
  margin: 58px 39px 0px 10px;
}
.flexRow__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle33 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
}
.highlights12 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  margin: 49px 0% 0px 5.06%;
}
.flexCol5 {
  @include flex-column;
  position: relative;
  margin: 77px 39px 0px 10px;
}
.flexRow__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.group {
  @include flex-column;
  position: relative;
  min-height: 1225px;
  margin: 77px 29px 0px 0px;
}
.flexCol6 {
  @include flex-column;
  position: absolute;
  top: 868px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol7 {
  @include flex-column;
  position: relative;
  margin: 47px 0px 0px;
}
.flexRow__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon11 {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 44px;
  margin: 0px 0px 3px;
}
.flexRow__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow__item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon2 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 6px 0px 2px;
}
.flexRow__spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.subtitle34 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  margin: 12px 0px 0px;
}
.flexRow3 {
  display: flex;
  position: relative;
  margin: 18px 0px 0px;
}
.flexRow3__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow3__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow3__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon21 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 8px 0px 0px;
}
.flexRow3__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.flexRow4 {
  display: flex;
  position: relative;
  margin: 27px 0px 0px;
}
.flexRow4__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow4__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow4__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow4__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.flexRow4__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow4__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow4__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon22 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 1px 0px 7px;
}
.flexRow4__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.flexCol8 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 341px;
  left: 10px;
  right: 10px;
}
.flexRow__item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights13 {
  margin: 49px 0% 0px 5.06%;
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  width: 94.94%;
}
.highlights13_span0 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span1 {
  font: $font_type_17;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span2 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span3 {
  font: $font_type_17;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span4 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span5 {
  font: $font_type_17;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span6 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span7 {
  font: $font_type_17;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span8 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span9 {
  font: $font_type_17;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights13_span10 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol9 {
  @include flex-column;
  position: relative;
  margin: 75px 39px 0px 10px;
}
.flexRow__item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image21 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 12px 0px 14px;
}
.flexRow__spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item8 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon23 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 0px 0px 2px;
}
.flexRow__spacer8 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle35 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  margin: 6px 0px 0px;
}
.highlights14 {
  font: $font_type_12;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  margin: 49px 0% 0px 5.06%;
}
.flexCol10 {
  @include flex-column;
  position: relative;
  margin: 59px 0px 0px 10px;
}
.flexRow__item9 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image22 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 17px 0px 14px;
}
.flexRow__spacer9 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item10 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon24 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 0px 0px 7px;
}
.flexRow__spacer10 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle36 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  margin: 11px 0px 0px;
}
.highlights15 {
  font: $font_type_12;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 91.47%;
  position: relative;
  margin: 49px 3.66% 0px 4.87%;
}
.flexCol11 {
  @include flex-column;
  position: relative;
  margin: 80px 0px 0px 10px;
}
.flexRow__item11 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image23 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 15px 0px 14px;
}
.flexRow__spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item12 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.icon25 {
  width: 39px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 39px;
  margin: 0px 0px 5px;
}
.flexRow__spacer12 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.highlights16 {
  margin: 49px 3.66% 0px 4.87%;
  font: $font_type_12;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  width: 91.47%;
}
.highlights16_span0 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span1 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span2 {
  font: $font_type_19;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span3 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span4 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span5 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span6 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span7 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span8 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span9 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span10 {
  font: $font_type_19;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span11 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span12 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span13 {
  font: $font_type_18;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span14 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span15 {
  font: $font_type_18;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span16 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span17 {
  font: $font_type_18;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span18 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span19 {
  font: $font_type_19;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span20 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span21 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span22 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span23 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span24 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span25 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span26 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span27 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span28 {
  font: $font_type_19;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span29 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span30 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span31 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span32 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span33 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span34 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span35 {
  font: $font_type_18;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights16_span36 {
  font: $font_type_16;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol12 {
  @include flex-column;
  position: relative;
  margin: 49px 0px 0px 10px;
}
.flexRow__item13 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer13 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item14 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow__spacer14 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle2 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 90.62%;
  position: relative;
  margin: 30px 3.62% 0px 5.76%;
}
.wrapper {
  @include flex-column;
  width: 41.04%;
  position: relative;
  min-height: 19px;
  margin: 15px 53.2% 0px 5.76%;
}
.wrapper1 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: -1px;
  left: 0px;
  right: 0px;
}
.highlights2 {
  @include font-face($font_type_13, $color_type_4, 0px);
  position: absolute;
  top: -1px;
  bottom: 1px;
  left: 0px;
  right: -145px;
}
.decorator {
  width: calc(100% - -352px);
  height: min-content;
  aspect-ratio: 5.97;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  bottom: -138px;
  left: 0px;
  right: -352px;
}
.wrapper2 {
  @include flex-column;
  width: 41.04%;
  position: relative;
  margin: 171px 53.2% 0px 5.76%;
}
.wrapper11 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  margin: 0px 0px 42px;
}
.highlights21 {
  @include font-face($font_type_13, $color_type_4, 0px);
  position: absolute;
  top: -1px;
  bottom: -19px;
  left: 0px;
  right: -507px;
}
.decorator1 {
  width: calc(100% - -351px);
  height: min-content;
  aspect-ratio: 5.96;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  bottom: -95px;
  left: 0px;
  right: -351px;
}
.wrapper3 {
  @include flex-column;
  width: 41.04%;
  position: relative;
  margin: 128px 53.2% 0px 5.76%;
}
.wrapper12 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 20px;
}
.highlights22 {
  @include font-face($font_type_13, $color_type_4, 0px);
  position: absolute;
  top: -1px;
  bottom: 1px;
  left: 0px;
  right: -508px;
}
.decorator2 {
  width: calc(100% - -352px);
  height: min-content;
  aspect-ratio: 5.97;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  bottom: -137px;
  left: 0px;
  right: -352px;
}
.wrapper4 {
  @include flex-column;
  width: 41.04%;
  position: relative;
  min-height: 19px;
  margin: 170px 53.2% 0px 5.76%;
}
.flexCol13 {
  @include flex-column;
  width: 94.24%;
  position: relative;
  margin: 302px 0% 0px 5.76%;
}
.flexCol14 {
  @include flex-column;
  position: relative;
}
.title {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.highlights17 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 21px 39px 0px 0px;
}
.group1 {
  @include flex-column;
  position: relative;
  min-height: 1505px;
  margin: 21px 0px 0px;
}
.flexCol15 {
  @include flex-column;
  position: absolute;
  top: 314px;
  bottom: 924px;
  left: 0px;
  right: 0px;
}
.highlights18 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 11px 39px 0px 0px;
}
.flexCol16 {
  @include flex-column;
  position: absolute;
  top: 607px;
  bottom: 667px;
  left: 0px;
  right: 0px;
}
.flexCol17 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 1349px;
  left: 0px;
  right: 0px;
}
.flexCol18 {
  @include flex-column;
  position: absolute;
  top: 167px;
  bottom: 1172px;
  left: 0px;
  right: 0px;
}
.flexCol19 {
  @include flex-column;
  position: absolute;
  top: 857px;
  bottom: 471px;
  left: 0px;
  right: 0px;
}
.title1 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.highlights19 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 52px 39px 0px 0px;
}
.flexCol20 {
  @include flex-column;
  position: absolute;
  top: 1034px;
  bottom: 325px;
  left: 0px;
  right: 0px;
}
.flexCol21 {
  @include flex-column;
  position: absolute;
  top: 1216px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.hero_title3 {
  font: $font_type_2;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.24%;
  position: relative;
  margin: 89px 0% 0px 5.76%;
}
.flexCol22 {
  @include flex-column;
  position: relative;
  margin: 77px 0px 0px 10px;
}
.flexRow__item15 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer15 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle37 {
  font: $font_type_9;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 1015px;
}
.highlights110 {
  font: $font_type_11;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 91.47%;
  position: relative;
  margin: 49px 3.66% 0px 4.87%;
}
.flexCol23 {
  @include flex-column;
  position: relative;
  margin: 48px 0px 0px 10px;
}
.flexRow__item16 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer16 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexCol24 {
  @include flex-column;
  position: relative;
  margin: 33px 0px 0px 10px;
}
.flexRow__item17 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer17 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexCol25 {
  @include flex-column;
  position: relative;
  margin: 46px 0px 0px 10px;
}
.flexRow__item18 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer18 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexCol26 {
  @include flex-column;
  width: 94.24%;
  position: relative;
  margin: 92px 0% 0px 5.76%;
}
.subtitle1 {
  margin: 21px 39px 0px 0px;
  font: $font_type_10;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.subtitle1_span0 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span2 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span3 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span4 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span5 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span6 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span7 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span8 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span9 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span10 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span11 {
  font: $font_type_20;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span12 {
  font: $font_type_21;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.group2 {
  @include flex-column;
  width: 94.24%;
  position: relative;
  min-height: 963px;
  margin: 52px 0% 0px 5.76%;
}
.flexCol27 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 742px;
  left: 0px;
  right: 0px;
}
.flexCol28 {
  @include flex-column;
  position: absolute;
  top: 404px;
  bottom: 389px;
  left: 0px;
  right: 0px;
}
.flexCol29 {
  @include flex-column;
  position: absolute;
  top: 595px;
  bottom: 166px;
  left: 0px;
  right: 0px;
}
.flexCol30 {
  @include flex-column;
  position: absolute;
  top: 815px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol31 {
  @include flex-column;
  position: absolute;
  top: 221px;
  bottom: 521px;
  left: 0px;
  right: 0px;
}
.flexCol32 {
  @include flex-column;
  position: relative;
  margin: 86px 0px 0px 10px;
}
.hero_title2 {
  margin: 0px 0% 0px 4.87%;
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  width: 95.13%;
}
.hero_title2_span0 {
  font: $font_type_22;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.hero_title2_span1 {
  font: $font_type_23;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.hero_title2_span2 {
  font: $font_type_22;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol33 {
  @include flex-column;
  position: relative;
  margin: 46px 39px 0px 0px;
}
.flexRow__item19 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer19 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle21 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 941px;
}
.flexCol34 {
  @include flex-column;
  position: relative;
  margin: 28px 39px 0px 0px;
}
.flexRow__item20 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer20 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item21 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer21 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexCol35 {
  @include flex-column;
  position: relative;
  margin: 23px 39px 0px 0px;
}
.flexRow__item22 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer22 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.hero_title21 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.24%;
  position: relative;
  margin: 77px 0% 0px 5.76%;
}
.flexCol36 {
  @include flex-column;
  position: relative;
  margin: 52px 0px 0px 10px;
}
.flexCol37 {
  @include flex-column;
  position: relative;
  margin: 0px 39px 0px 0px;
}
.flexRow__item23 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer23 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item24 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow__spacer24 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle22 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  margin: 6px 0px 0px;
}
.flexCol38 {
  @include flex-column;
  width: 95.13%;
  position: relative;
  margin: 0px 0% 0px 4.87%;
}
.subtitle23 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.flexCol39 {
  @include flex-column;
  position: relative;
  margin: 47px 0px 0px 10px;
}
.flexRow__item25 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer25 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item26 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow__spacer26 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle4 {
  font: $font_type_7;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  margin: 11px 0px 0px;
}
.flexCol40 {
  @include flex-column;
  position: relative;
  margin: 60px 0px 0px 10px;
}
.flexRow__item27 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer27 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item28 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow__spacer28 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.subtitle41 {
  font: $font_type_7;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  margin: 9px 0px 0px;
}
.flexCol41 {
  @include flex-column;
  position: relative;
  margin: 19px 0px 0px 10px;
}
.flexRow__item29 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer29 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow__item30 {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.flexRow__spacer30 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.group3 {
  @include flex-column;
  width: 94.24%;
  position: relative;
  min-height: 2981px;
  margin: 50px 0% 0px 5.76%;
}
.flexCol42 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 2760px;
  left: 0px;
  right: 0px;
}
.flexCol43 {
  @include flex-column;
  position: absolute;
  top: 221px;
  bottom: 2539px;
  left: 0px;
  right: 0px;
}
.flexCol44 {
  @include flex-column;
  position: absolute;
  top: 474px;
  bottom: 2198px;
  left: 0px;
  right: 0px;
}
.flexCol45 {
  @include flex-column;
  position: absolute;
  top: 804px;
  bottom: 1868px;
  left: 0px;
  right: 0px;
}
.flexCol46 {
  @include flex-column;
  position: absolute;
  top: 1057px;
  bottom: 1615px;
  left: 0px;
  right: 0px;
}
.highlights111 {
  font: $font_type_12;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 21px 39px 0px 0px;
}
.flexCol47 {
  @include flex-column;
  position: absolute;
  top: 1387px;
  bottom: 1285px;
  left: 0px;
  right: 0px;
}
.flexCol48 {
  @include flex-column;
  position: absolute;
  top: 1668px;
  bottom: 977px;
  left: 0px;
  right: 0px;
}
.flexCol49 {
  @include flex-column;
  position: absolute;
  top: 2025px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.title2 {
  font: $font_type_3;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.highlights112 {
  font: $font_type_12;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 64px 39px 0px 0px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_1;
  width: 73.63%;
  position: relative;
  margin: 96px 14.95% 0px 11.42%;
}
.flexCol50 {
  @include flex-column;
  width: 81.21%;
  position: relative;
  flex-grow: 1;
  margin: 68px auto 53px;
}
.title3 {
  font: $font_type_3;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
}
.title3_span0 {
  font: $font_type_24;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title3_span1 {
  font: $font_type_24;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title3_span2 {
  font: $font_type_24;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 25.16%;
  position: relative;
  margin: 39px auto 0px;
}
.subtitle5 {
  @include font-face($font_type_8, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 39px;
}
