@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 54px 54px 54px 54px;

$font_type_0: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 26px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 22px/1.14 "Sora", Helvetica, Arial, serif;
$font_type_5: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_6: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_8: 0.9em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_9: 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_10: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_11: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_12: 700 1em/1.14 "Sora", Helvetica, Arial, serif;
$font_type_13: 0.8181818181818182em/1.4 "Roboto", Helvetica, Arial, serif;
$font_type_14: 600 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_15: 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_16: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 76.46%;
  position: relative;
  flex-grow: 1;
  margin: 76px 12.5% 60px 11.04%;
}
.flexCol1 {
  @include flex-column;
  width: 93.55%;
  position: relative;
  margin: 0px 1.73% 0px 4.72%;
}
.medium_title1 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  position: relative;
}
.highlights {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  margin: 40px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 54px 3.09% 0px 4.72%;
}
.title {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: justify;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 35px;
}
.subtitle2 {
  margin: 21px 39px 0px 0px;
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
    margin: 21px 32px 0px 0px;
  }
  position: relative;
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
}
.subtitle2_span0 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span1 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span2 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span3 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span4 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span5 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span6 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span7 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span8 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span9 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span10 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span11 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span12 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span13 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol3 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 71px 3.09% 0px 4.72%;
}
.highlights1 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 1363px;
  margin: 21px 39px 0px 0px;
}
.flexCol4 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 53px 2.91% 0px 4.9%;
}
.highlights2 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 239px;
  margin: 21px 39px 0px 0px;
}
.highlights3 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 103px;
  margin: 21px 39px 0px 0px;
}
.flexCol5 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 53px 3.09% 0px 4.72%;
}
.highlights4 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 483px;
  margin: 21px 39px 0px 0px;
}
.highlights5 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  margin: 21px 39px 0px 0px;
}
.flexCol6 {
  @include flex-column;
  width: 90.19%;
  @include lg {
    width: 92.46%;
  }
  @include md {
    width: 94.23%;
  }
  @include sm {
    width: 95.61%;
  }
  @include xs {
    width: 96.67%;
  }
  @include xxs {
    width: 97.48%;
  }
  @include tn {
    width: 98.1%;
  }
  position: relative;
  margin: 26px auto 0px;
}
.flexRow {
  display: flex;
  position: relative;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 44px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle21 {
  margin: 9px 0px 0px;
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
}
.subtitle21_span0 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle21_span1 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow1 {
  display: flex;
  position: relative;
  margin: 21px 0px 0px;
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle22 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  margin: 9px 0px 0px;
}
.flexRow2 {
  display: flex;
  position: relative;
  margin: 30px 0px 0px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol7 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 10% 5.09% 0px 0%;
}
.flexCol8 {
  @include flex-column;
  @include lg {
    margin: 0px 0px 0px 0px;
  }
  @include sm {
    margin: 0px 0px 0px 8px;
  }
  position: relative;
  margin: 0px 0px 0px 0px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image5 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 5px 0px 15px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle23 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  min-height: 35px;
}
.highlights6 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 161px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol9 {
  @include flex-column;
  @include lg {
    margin: 89px 0px 0px 12px;
  }
  @include sm {
    margin: 89px 0px 0px 8px;
  }
  position: relative;
  margin: 89px 0px 0px 0px;
}
.flexRow__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights7 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 129px;
  margin: 48px 0% 0px 0%;
}
.flexCol10 {
  @include flex-column;
  @include lg {
    margin: 88px 12px 0px 0px;
  }
  @include sm {
    margin: 88px 8px 0px 0px;
  }
  position: relative;
  margin: 88px 17px 0px 0px;
}
.flexRow__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights8 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 185px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol11 {
  @include flex-column;
  @include lg {
    margin: 89px 12px 0px 0px;
  }
  @include sm {
    margin: 89px 8px 0px 0px;
  }
  position: relative;
  margin: 89px 17px 0px 0px;
}
.flexRow__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexCol12 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 143px 3.09% 0px 4.72%;
}
.flexCol13 {
  @include flex-column;
  @include lg {
    margin: 41px 16px 0px 0px;
  }
  @include sm {
    margin: 41px 12px 0px 0px;
  }
  @include xxs {
    margin: 41px 8px 0px 0px;
  }
  position: relative;
  margin: 41px 22px 0px 0px;
}
.flexRow__item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow__spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle21_span2 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow1__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle24 {
  margin: 9px 0px 0px;
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
}
.subtitle24_span0 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle24_span1 {
  font: $font_type_7;
  color: $color_type_4;
  text-decoration: underline;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow2__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol14 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 10% 5.09% 0px 0%;
}
.flexRow__item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights9 {
  margin: 48px 0% 0px 5.06%;
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 2266px;
  width: 94.94%;
}
.highlights9_span0 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span1 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span2 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span3 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span4 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span5 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights9_span6 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol15 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 10% 5.09% 0px 0%;
}
.flexRow__item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights10 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 2035px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol16 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 46px 1.54% 0px 0%;
}
.highlights11 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 22px 20px 0px 0px;
  }
  @include lg {
    margin: 22px 32px 0px 0px;
  }
  @include md {
    margin: 22px 28px 0px 0px;
  }
  @include xxs {
    margin: 22px 16px 0px 0px;
  }
  position: relative;
  margin: 22px 39px 0px 0px;
}
.flexCol17 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 10% 5.09% 0px 0%;
}
.flexRow3 {
  display: flex;
  @include lg {
    margin: 0px 4px;
  }
  position: relative;
  margin: 0px 8px;
}
.flexRow3__item {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow3__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights12 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 242px;
  margin: 64px 0px 0px;
}
.flexCol18 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 10% 5.09% 0px 0%;
}
.flexRow3__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow3__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights13 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 1841px;
  margin: 64px 0px 0px;
}
.flexCol19 {
  @include flex-column;
  width: 92.19%;
  position: relative;
  margin: 73px 0% 0px 7.81%;
}
.subtitle4 {
  margin: 34px 18.23% 0px 0%;
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include md {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  width: 81.77%;
}
.subtitle4_span0 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span1 {
  @include font-face($font_type_13, $color_type_4, 0.5px);
}
.subtitle4_span2 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span3 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span4 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span5 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span6 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span7 {
  @include font-face($font_type_13, $color_type_4, 0.5px);
}
.subtitle4_span8 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span9 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span10 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span11 {
  @include font-face($font_type_13, $color_type_4, 0.5px);
}
.subtitle4_span12 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span13 {
  @include font-face($font_type_13, $color_type_4, 0.5px);
}
.subtitle4_span14 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span15 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span16 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span17 {
  @include font-face($font_type_13, $color_type_4, 0.5px);
}
.subtitle4_span18 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span19 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box4 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_1;
  width: 72.03%;
  position: relative;
  margin: 170px 11.53% 0px 16.44%;
}
.flexCol20 {
  @include flex-column;
  width: 81.21%;
  @include sm {
    width: 85.22%;
  }
  @include xs {
    width: 88.49%;
  }
  @include xxs {
    width: 91.11%;
  }
  @include tn {
    width: 93.18%;
  }
  position: relative;
  flex-grow: 1;
  margin: 68px auto 53px;
}
.title1 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: center;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 161px;
}
.title1_span0 {
  font: $font_type_16;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title1_span1 {
  font: $font_type_16;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title1_span2 {
  font: $font_type_16;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 45.16%;
  position: relative;
  margin: 0% auto 0px;
}
.subtitle3 {
  @include font-face($font_type_3, $color_type_1, 0px);
  @include md {
    margin: 15px 28px;
  }
  @include lg {
    margin: 15px 32px;
  }
  @include sm {
    margin: 15px 20px;
  }
  @include xxs {
    margin: 15px 16px;
  }
  position: relative;
  flex-grow: 1;
  margin: 15px 39px;
}
