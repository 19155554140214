@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(230, 186, 70);
$color_type_2: rgb(255, 255, 255);
$color_type_3: rgb(28, 62, 146);

$border_radius_type_0: 10px 10px 10px 10px;
$border_radius_type_1: 6px 6px 6px 6px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 18px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_4: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 0.9em/1.4 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  height: 2524px;
  position: relative;
  @include md {
    display: none;
  }
}
.flexCol {
  @include flex-column;
  width: 73.47%;
  position: relative;
  flex-grow: 1;
  margin: 55px 11.94% 136px 14.58%;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_3, 0px);
  width: 100%;
  position: relative;
  min-height: 105px;
  margin: 0px 32.51% 0px 0%;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 0px 1px 0px 0px;
}
.highlights {
  font: $font_type_2;
  color: $color_type_3;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 76px;
  margin: 0px 0px 0px 1px;
}
.rect3 {
  background-color: $color_type_2;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  height: 285px;
  position: relative;
  margin: 12px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  margin: 30px 1px 0px 0px;
}
.highlights1 {
  font: $font_type_2;
  color: $color_type_3;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 26px;
  margin: 0px 0px 0px 1px;
}
.rect31 {
  background-color: $color_type_2;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  height: 285px;
  position: relative;
  margin: 10px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 32px 0px 0px 1px;
}
.highlights2 {
  font: $font_type_2;
  color: $color_type_3;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 51px;
}
.rect32 {
  background-color: $color_type_2;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  height: 285px;
  position: relative;
}
.flexCol4 {
  @include flex-column;
  position: relative;
  margin: 42px 0px 0px 1px;
}
.highlights3 {
  font: $font_type_2;
  color: $color_type_3;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 51px;
  margin: 0px 1px 0px 0px;
}
.rect33 {
  background-color: $color_type_2;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  height: 285px;
  position: relative;
  margin: 25px 0px 0px;
}
.subtitle1 {
  margin: 42px 0px 0px 1px;
  font: $font_type_1;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 530px;
}
.subtitle1_span0 {
  font: $font_type_4;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_5;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow {
  display: flex;
  width: 100%;
  position: relative;
  margin: 119px 0.85% 0px 0%;
}
.content_box3 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  flex: 0 1 188px;
  align-self: flex-end;
  align-items: center;
  margin: 0px 0px 1px 0%;
}
.highlights11 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex-grow: 1;
  min-height: 22px;
  margin: 10px 9px 9px 0px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 26px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 40%;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  position: relative;
  align-items: center;
  flex: 0 1 151px;
  border-width: 0;
}
.group {
  @include flex-column;
  height: 22px;
  width: 100%;
  position: relative;
  margin: 15px 2px 9px 22px;
}
.highlights21 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0% !important;
}
.image3 {
  width: 10%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
