@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(253, 194, 12);
$color_type_2: rgb(255, 255, 255);
$color_type_3: rgb(28, 62, 146);

$font_type_0: 600 32px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 32px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 13px/1.23 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 12px/2.1 "Sora", Helvetica, Arial, serif;
$font_type_4: 600 1em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_6: 0.75em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_7: 0.5625em/1.27 "Sora", Helvetica, Arial, serif;
$font_type_8: 1.5em/1.27 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include xmudder{
    display: none;
  }
}
.group {
  @include flex-column;
  height: 1248px;
  position: relative;
  margin: 0px 0px 4px;
}
.rect {
  background-color: $color_type_3;
  height: 1222px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 0px 0px;
}
.flexCol {
  @include flex-column;
  width: 81.64%;
  position: absolute;
  top: 0px;
  bottom: 139px;
  left: 0px;
  right: 0px;
  margin: 0px 4.35% 0px 14.01%;
}
.flexRow {
  display: flex;
  width: 78.99%;
  position: relative;
  margin: 0px 0.89% 0px 20.12%;
}
.flexRow1 {
  @include flex-item(0, 1, 103px);
  margin: 1px 0px 0px;
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 23px;
}
.icon1 {
  width: 23px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 23px;
  margin: 5px 0px 14px;
}
.desc {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex: 1 1 79px;
  margin: 0px 0px 0px 1px;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 127px;
}
.flexRow2 {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 1px 5.7px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image4 {
  width: 25px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 25px;
  margin: 6px 0px 8px;
}
.desc1 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex: 1 1 91px;
  margin: 0px 0px 0px 5.3px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 13px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;
}
.image3 {
  width: 24px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 24px;
  margin: 11px 0px 12px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 34px 0px 0px 37px;
}
.title {
  @include font-face($font_type_0, $color_type_0, -0.5px);
  position: relative;
}
.title_span0 {
  font: $font_type_4;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: -0.5px;
}
.title_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: -0.5px;
}
.flexCol2 {
  @include flex-column;
  width: 54.15%;
  position: relative;
  margin: 11px 35.55% 0px 10.3%;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image1 {
  width: 44px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 44px;
  margin: 0px auto;
}
.text {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_1, 0px);
  position: relative;
  margin: 15px 16px 0px 18px;
}
.image2 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  margin: 9px 0px 0px;
}
.text1 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_2, 0px);
  position: relative;
  margin: 8px 38px 0px;
}
.title1 {
  margin: 20px 27px 0px 0px;
  font: $font_type_8;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0px;
  position: relative;
}
.title1_span0 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.title1_span1 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.title1_span2 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.title1_span3 {
  font: $font_type_7;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
