@use "utils" as *;

$color_type_0: rgb(230, 186, 70);
$color_type_1: rgb(240, 239, 239);
$color_type_2: rgb(255, 255, 255);
$color_type_3: rgb(28, 62, 146);

$border_radius_type_0: 10px 10px 10px 10px;
$border_radius_type_1: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 18px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_4: 400 16px/1.57 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include md {
    display: none;
  }
}
.group {
  @include flex-column;
  width: 74.17%;
  height: 409px;
  position: relative;
  margin: 112px 12.15% 47px 13.68%;
}
.flexCol {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 17px;
}
.flexRow {
  display: flex;
  position: relative;
  margin: 0px 13px;
  @include xs {
    display: block;
  }
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_3, 0px);
  position: relative;
  flex: 0 1 494px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 11.5px;
}
.wrapper2 {
  @include flex-column;
  height: 82px;
  position: relative;
  flex: 0 1 309px;
  margin: 9px 0px;
}
.wrapper23 {
  @include flex-column;
  height: 82px;
  position: relative;
  flex: 0 1 309px;
  margin: 9px 0px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_3;
  border-radius: $border_radius_type_0;
  width: 48.87%;
  position: relative;
  flex-grow: 1;
  margin: 29px auto 11px;
}
.highlights2 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex-grow: 1;
  margin: 6% 40%;
}
.rect6 {
  border-radius: $border_radius_type_0;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  width: 156px;
  height: 41px;
  position: absolute;
  top: 30px;
  left: 250px;
  z-index: 1;
}
.highlights3 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0px;
  //width: 111px;
  //height: 22px;
  position: absolute;
  //top: 40px;
  //left: 273px;
  margin: -6% 40%;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  position: relative;
  margin: 12px 0px 0px;
}
.flexCol1 {
  @include flex-column;
  width: 83.16%;
  position: relative;
  flex-grow: 1;
  margin: 37px auto 20px;
}
.medium_title {
  @include font-face($font_type_2, $color_type_3, 0.5px);
  width: 100%;
  position: relative;
  margin: 0px 0% 0px 0%;
}
.flexRow1 {
  display: flex;
  position: relative;
  margin: 7px 0px 0px;
  @include xs {
    display: block;
  }
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 194px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.icon1 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 100%;
  margin-right: 10px;
}
.highlights {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 43px;
  margin: 20px 14px 0px 4px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 95px;
}
.wrapper1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  height: 123px;
  position: relative;
}
.highlights1 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 43px;
  margin: 20px 9px 0px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 134px;
}
.flexRow1__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  flex: 0 1 194px;
}
.wrapper11 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  height: 123px;
  position: relative;
  margin: 0px 0px;
}
.highlights4 {
  @include h-center-content;
  font: $font_type_4;
  color: $color_type_3;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 43px;
  margin: 20px 0px 0px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 88px;
  bottom: 212px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 43px 35px 17px 38px;
}
