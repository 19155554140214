@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 16px/1.57 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section4 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include md {
    display: none;
  }
}
.flexRow {
  display: flex;
  width: 73.54%;
  position: relative;
  flex-grow: 1;
  margin: 20px auto 48px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 440px;
  margin: 100px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  width: 79.09%;
  position: relative;
  margin: 0px 17.73% 0px 3.18%;
}
.group {
  @include flex-column;
  height: 509px;
  position: relative;
  margin: 298px 0px 0px;
}
.content_box8 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 90.45%;
  position: absolute;
  top: 33px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 9.55% 0px 0%;
}
.flexCol1 {
  @include flex-column;
  width: 59.55%;
  position: relative;
  flex-grow: 1;
  margin: 102px auto 65px;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
}
.icon4 {
  width: 87px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 87px;
  margin: 0px auto;
}
.subtitle {
  margin: 26px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 196px;
}
.subtitle_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 400px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 29px;
  position: relative;
  flex-grow: 1;
  min-width: 29px;
  margin: 43px auto 33px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 186px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 433px;
  margin: 0px 0px 261px;
}
.group1 {
  @include flex-column;
  height: 531px;
  position: relative;
  margin: 0px 0px 0px 7px;
}
.content_box11 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 55px;
  bottom: 0px;
  left: 0px;
  right: 28px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 88px 16px 28px 37px;
}
.flexCol3__item {
  @include flex-column;
  position: relative;
}
.icon3 {
  width: 84px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 84px;
  margin: 0px auto;
}
.subtitle1 {
  margin: 25px 38px 0px 18px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 136px;
}
.subtitle1_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 115px;
}
.content_box12 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 422px;
  min-width: 109px;
  right: 0px;
}
.hero_title41 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 43px 39px 31px 38px;
}
.hero_title11 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  width: 86.84%;
  position: relative;
  min-height: 204px;
  margin: 61px 13.16% 0px 0%;
}
