@use "utils" as *;

$color_type_0: rgb(230, 186, 70);
$color_type_1: rgb(240, 239, 239);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 10px 10px 10px 10px;
$border_radius_type_1: 44px 44px 44px 44px;

$font_type_0: 700 25px/1.28 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 18px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 13px/1.93 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include xmudder{
    display: none;
  }
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 68px 36.7px 42px 35.7px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 0px 9.3px 0px 8.3px;
}
.medium_title {
  @include v-center-content;
  justify-content: center;
  font: $font_type_0;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  margin: 0px 36px;
}
.flexRow {
  display: flex;
  position: relative;
  margin: 41px 0px 0px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 0 1 151px;
}
.highlights {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex-grow: 1;
  min-height: 22px;
  margin: 11px 16px 9px 35%;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 17px;
}
.content_box1 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  flex: 0 1 156px;
  margin: 1px 0px 0px;
}
.highlights1 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex-grow: 1;
  margin: 10px 22px 9px 40%;
}
.group {
  @include flex-column;
  height: 293px;
  position: relative;
  margin: 50px 0px 0px;
}
.background {
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  height: 260px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 33px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 45px;
  left: 0px;
  right: 0px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.icon2 {
  width: 66px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 66px;
  margin: 0px auto;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 1px 0px 0px;
}
.medium_title1 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  width: 72.31%;
  position: relative;
  margin: 0px 15.87% 5% 11.83%;
}
.flexRow1 {
  display: flex;
  position: relative;
}
.flexCol4 {
  @include flex-column;
  position: relative;
  flex: 0 1 24%;
}
.flexCol4__item {
  @include flex-column;
  position: relative;
}
.icon {
  width: 80%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  margin: 0px 11px 0px 10px;
}
.text2 {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  margin: 7px 0px 0px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 2.4px;
}
.flexCol5 {
  @include flex-column;
  position: relative;
  flex: 0 1 24%;
}
.wrapper1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  height: 50px;
  position: relative;
  margin: 0px 11px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 3.2px;
}
.flexCol6 {
  @include flex-column;
  position: relative;
  flex: 0 1 24%;
}
.wrapper11 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  height: 50px;
  position: relative;
  margin: 0px 14px 0px 21px;
}
.flexRow1__spacer2 {
  @include flex-column;
  position: relative;
}
.flexCol7 {
  @include flex-column;
  position: relative;
  flex: 0 1 24%;
}
.wrapper12 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  height: 50px;
  position: relative;
  margin: 0px 29px;
}
