@use "utils" as *;

$color_type_0: rgb(107, 111, 130);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(27, 65, 135);
$color_type_3: rgb(46, 67, 132);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 39px 39px 0px 0px;
$border_radius_type_2: 0px 0px 39px 39px;

$font_type_0: 600 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 18px/1.25 "Quicksand", Helvetica, Arial, serif;
$font_type_2: 400 14px/1.8 "Open Sans", Helvetica, Arial, serif;
$font_type_3: 400 13px/1.74 "Open Sans", Helvetica, Arial, serif;

.root {
  @include flex-column;
  margin-top: 10%;
  width: 90vw;
  align-self: center;
  position: relative;
  @include xmudder {
    display: none;
  }
}
.root__item {
  @include flex-column;
  position: relative;
}
.image {
  border-radius: $border_radius_type_1;
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
}
.content_box {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_2;
  box-shadow: 0px 2px 18px 0px rgba(49, 53, 72, 0.098);
  position: relative;
  margin: 0px 0px 0px 1px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 16px 0px 50px 19px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.highlights {
  @include font-face($font_type_1, $color_type_2, 0.699999988079071px);
  position: relative;
  min-height: 24px;
}
.text {
  @include font-face($font_type_3, $color_type_0, 0.5px);
  position: relative;
  margin: 1px 0px 0px;
}
.paragraph {
  @include font-face($font_type_2, $color_type_0, 0.5px);
  width: 95%;
  position: relative;
  margin: 12px 0% 0px 0%;
  text-align: justify;
}
.box {
  @include flex-column;
  background-color: $color_type_3;
  border-radius: $border_radius_type_0;
  width: 57.59%;
  position: relative;
  margin: 50px 27.53% 0px 14.87%;
}
.flexRow {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 17px 25px;
  justify-content: center;
}
.flexRowBut {
  border-width: 0px !important;
  background-color: transparent;
}
.subtitle {
  @include font-face($font_type_0, $color_type_1, 0px);
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 33px;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.icon {
  width: 20px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 20px;
  margin: 3px 0px 5px;
}
