@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 16px/1.57 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include md {
    display: none;
  }
}
.flexRow {
  display: flex;
  width: 73.61%;
  position: relative;
  flex-grow: 1;
  margin: 52px auto 0px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 509px;
  margin: 88px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  position: relative;
  margin: 0px 0px 0px 15px;
}
.group {
  @include flex-column;
  width: 83.89%;
  height: 497px;
  position: relative;
  margin: 34px 16.11% 0px 0%;
}
.content_box7 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 21px;
  bottom: 0px;
  left: 0px;
  right: 29px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 97px 15px 35px 38px;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
}
.image4 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.subtitle {
  margin: 46px 11px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
}
.subtitle_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 115px;
  margin: 34px 0px 0px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 388px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 24.77%;
  position: relative;
  flex-grow: 1;
  margin: 43px 40.37% 25px 34.86%;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 125px;
}
.group1 {
  @include flex-column;
  height: 531px;
  position: relative;
  flex: 0 1 426px;
  margin: 0px 0px 288px;
}
.content_box4 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 55px;
  bottom: 0px;
  left: 0px;
  right: 28px;
}
.flexCol2 {
  @include flex-column;
  width: 72.61%;
  position: relative;
  flex-grow: 1;
  margin: 72px auto 59px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.icon2 {
  width: 77px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 77px;
  margin: 0px auto;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 48px 0px 0px;
}
.subtitle1 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 87px;
}
.subtitle1_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  margin: 7px 0px 0px;
}
.content_box11 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 422px;
  min-width: 109px;
  right: 0px;
}
.hero_title41 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 29px;
  position: relative;
  flex-grow: 1;
  min-width: 29px;
  margin: 43px auto 21px;
}
