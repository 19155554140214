@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 22px 22px 22px 22px;
$border_radius_type_2: 54px 54px 54px 54px;

$font_type_0: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 22px/1.14 "Sora", Helvetica, Arial, serif;
$font_type_4: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_6: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_8: 700 1em/1.14 "Sora", Helvetica, Arial, serif;
$font_type_9: 600 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_10: 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_11: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 1656px;
}
.flexCol {
  @include flex-column;
  width: 80.35%;
  position: relative;
  flex-grow: 1;
  margin: 52px 12.15% 382.67px 7.5%;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 0px 19px 0px 38px;
}
.flexCol2 {
  @include flex-column;
  width: 93.64%;
  position: relative;
  margin: 0px 0% 0px 6.36%;
}
.highlights {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 107px;
}
.title {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 35px;
  margin: 68px 15px 0px 0px;
}
.highlights1 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 142px;
  margin: 21px 0px 0px;
}
.title1 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 35px;
  margin: 50px 15px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  width: 96.18%;
  position: relative;
  margin: 461px 3.82% 0px 0%;
}
.flexCol3__cell {
  @include flex-column;
  position: relative;
}
.group {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 273px;
}
.flexCol4 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.rect4 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: relative;
  min-height: 82px;
}
.highlights2 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: relative;
  min-height: 161px;
  margin: 30px 0% 0px 7.75%;
}
.subtitle2 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 209px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.image4 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.group1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 273px;
  margin: 75px 0px 0px;
}
.group2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 273px;
  margin: 30px 0px 0px;
}
.group3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 273px;
  margin: 94px 0px 0px;
}
.group4 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 273px;
  margin: 48px 0px 0px;
}
.flexCol5 {
  @include flex-column;
  position: relative;
  margin: 108px 3px 0px 22px;
}
.title2 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.42%;
  position: relative;
  min-height: 35px;
  margin: 0px 0% 0px 5.58%;
}
.flexCol5__cell {
  @include flex-column;
  position: relative;
}
.group5 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 415px;
  margin: 651px 17px 0px 0px;
}
.highlights3 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
}
.highlights3_box {
  position: relative;
  min-height: 509px;
  margin: 22px 39px 0px 0px;
}
.highlights3_span0 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span1 {
  font: $font_type_7;
  color: $color_type_4;
  text-decoration: underline;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span2 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle21 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 351px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.group6 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 362px;
  margin: 45px 17px 0px 0px;
}
.group7 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  min-height: 362px;
  left: 0px;
  right: 0px;
}
.subtitle22 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 298px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.group8 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 362px;
  margin: 94px 17px 0px 0px;
}
.group9 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 450px;
  margin: 94px 17px 0px 0px;
}
.subtitle23 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 386px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.flexCol6 {
  @include flex-column;
  width: 88.68%;
  position: relative;
  margin: 80px 0% 0px 11.32%;
}
.flexCol7 {
  @include flex-column;
  position: relative;
  margin: 0px 0px 0px 11px;
}
.title3 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 35px;
}
.highlights4 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 65px;
  margin: 30px 39px 0px 0px;
}
.flexCol7__cell {
  @include flex-column;
  position: relative;
}
.image5 {
  width: 73.99%;
  height: auto;
  aspect-ratio: 1.61;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  margin: 0px 22.56% 0px 3.45%;
}
.flexCol8 {
  @include flex-column;
  position: relative;
  margin: 80px 39px 0px 0px;
}
.highlights5 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 262px;
  margin: 0px 0px 0px 11px;
}
.highlights6 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 214px;
  margin: 300px 11px 0px 0px;
}
.flexCol9 {
  @include flex-column;
  width: 94.81%;
  position: relative;
  margin: 75px 0% 0px 5.19%;
}
.flexCol10 {
  @include flex-column;
  width: 92.53%;
  position: relative;
  margin: 0px 0% 0px 7.47%;
}
.highlights7 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 140px;
  margin: 30px 39px 0px 0px;
}
.flexCol9__cell {
  @include flex-column;
  position: relative;
}
.group10 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 364px;
  margin: 584px 39px 0px 0px;
}
.group11 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  min-height: 364px;
  left: 0px;
  right: 0px;
}
.highlights8 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: relative;
  margin: 30px 0% 0px 7.75%;
}
.subtitle24 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 300px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.group12 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 320px;
  margin: 84px 39px 0px 0px;
}
.highlights9 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 208px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle25 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 256px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect41 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 238px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group13 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 320px;
  margin: 88px 39px 0px 0px;
}
.group14 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 320px;
  margin: 76px 39px 0px 0px;
}
.group15 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 288px;
  margin: 76px 39px 0px 0px;
}
.highlights10 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 176px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle26 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 224px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect42 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 206px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group16 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 328px;
  margin: 76px 39px 0px 0px;
}
.highlights11 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 216px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle27 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 264px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect43 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 246px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.flexCol__cell {
  @include flex-column;
  position: relative;
}
.group17 {
  @include flex-column;
  width: 91.44%;
  position: relative;
  flex-grow: 1;
  min-height: 280px;
  margin: 76px 3.37% 0px 5.19%;
}
.group18 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  min-height: 280px;
  left: 0px;
  right: 0px;
}
.highlights12 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 168px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle28 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 216px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect44 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 198px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group19 {
  @include flex-column;
  width: 91.44%;
  position: relative;
  flex-grow: 1;
  min-height: 339px;
  margin: 76px 3.37% 0px 5.19%;
}
.highlights13 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle29 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 275px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect45 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 257px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.flexCol11 {
  @include flex-column;
  width: 87.73%;
  position: relative;
  margin: 119px 0% 0px 12.27%;
}
.flexCol12 {
  @include flex-column;
  position: relative;
}
.highlights14 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 22px 39px 0px 0px;
}
.flexCol13 {
  @include flex-column;
  position: relative;
  margin: 39px 0px 0px;
}
.title4 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 64px;
}
.subtitle210 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 74px;
  margin: 36px 39px 0px 0px;
}
.flexCol14 {
  @include flex-column;
  position: relative;
  margin: 56px 0px 0px;
}
.highlights15 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 111px;
  margin: 22px 39px 0px 0px;
}
.highlights16 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 89px;
  margin: 22px 39px 0px 0px;
}
.flexCol15 {
  @include flex-column;
  width: 94.81%;
  position: relative;
  margin: 76px 0% 0px 5.19%;
}
.highlights17 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 46px;
  margin: 29px 39px 0px 0px;
}
.flexCol15__cell {
  @include flex-column;
  position: relative;
}
.group20 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 254px;
  margin: 330px 39px 0px 0px;
}
.subtitle211 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 190px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect46 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 172px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights18 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group21 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 260px;
  margin: 36px 39px 0px 0px;
}
.subtitle212 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 196px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect47 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 178px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights19 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  min-height: 132px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group22 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 905px;
  margin: 78px 39px 0px 0px;
}
.subtitle213 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 841px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect48 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 823px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights20 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 549px;
  bottom: 0px;
  min-height: 356px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.flexCol16 {
  @include flex-column;
  width: 94.81%;
  position: relative;
  margin: 27px 5.19% 0px 0%;
}
.title5 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.53%;
  position: relative;
  min-height: 35px;
  margin: 0px 0% 0px 7.47%;
}
.flexCol16__cell {
  @include flex-column;
  position: relative;
}
.group23 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 278px;
  margin: 834px 39px 0px 0px;
}
.subtitle214 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 214px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect49 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 196px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group24 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 225px;
  margin: 24px 39px 0px 0px;
}
.subtitle215 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 161px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect410 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 143px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group25 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 203px;
  margin: 43px 39px 0px 0px;
}
.subtitle216 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 139px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect411 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 121px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights21 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  min-height: 75px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group26 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 204px;
  margin: 65px 39px 0px 0px;
}
.subtitle217 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 140px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect412 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 122px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group27 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 204px;
  margin: 63px 39px 0px 0px;
}
.group28 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 204px;
  margin: 64px 39px 0px 0px;
}
.highlights22 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: -1.4000000000000001px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group29 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 233px;
  margin: 63px 39px 0px 0px;
}
.subtitle218 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 169px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect413 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 151px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights23 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  min-height: 105px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group30 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 233px;
  margin: 46px 39px 0px 0px;
}
.group31 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 163px;
  margin: 46px 39px 0px 0px;
}
.subtitle219 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 99px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.rect414 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 81px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.highlights24 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 128px;
  bottom: 0px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 5.67% 0px 2.08%;
}
.group32 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 204px;
  margin: 46px 39px 0px 0px;
}
.flexCol17 {
  @include flex-column;
  width: 87.73%;
  position: relative;
  margin: 74px 10.54% 0px 1.73%;
}
.flexCol18 {
  @include flex-column;
  position: relative;
  margin: 94px 30px 0px 20px;
}
.title6 {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 91.69%;
  position: relative;
  min-height: 35px;
  margin: 0px 8.13% 0px 0.18%;
}
.subtitle3 {
  font: $font_type_3;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
}
.subtitle3_box {
  position: relative;
  min-height: 2617px;
  margin: 47px 0px 0px;
}
.subtitle3_span0 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span1 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span2 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span3 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span4 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span5 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span6 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span7 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span8 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span9 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span10 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span11 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span12 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span13 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span14 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span15 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span16 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span17 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span18 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span19 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span20 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span21 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span22 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_2;
  width: 68.54%;
  position: relative;
  margin: 51.33px 16.7% 0px 14.76%;
}
.flexCol19 {
  @include flex-column;
  width: 81.21%;
  position: relative;
  flex-grow: 1;
  margin: 44.78px 10.08% 64.61px 8.71%;
}
.title11 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
}
.title11_box {
  @include h-center-content;
  position: relative;
}
.title11_span0 {
  font: $font_type_11;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span1 {
  font: $font_type_11;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span2 {
  font: $font_type_11;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 25.16%;
  position: relative;
  margin: 50.61px 40.58% 0px 34.27%;
}
.subtitle4 {
  @include font-face($font_type_2, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 39px;
}
