@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section7 {
  @include flex-column;
  height: 1109px;
  position: relative;
  @include md {
    display: none;
  }
}
.flexRow {
  display: flex;
  width: 72.64%;
  position: relative;
  flex-grow: 1;
  margin: 0px auto 494px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  position: relative;
  flex: 1 1 494px;
  margin: 97px 0px 368px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 125px;
}
.group {
  @include flex-column;
  height: 615px;
  position: relative;
  flex: 0 1 427px;
}
.content_box21 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 21px;
  bottom: 0px;
  left: 0px;
  right: 29px;
}
.subtitle {
  margin: 115px 27px 0px 35px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  min-height: 479px;
}
.subtitle_span0 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 506px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 43px 21px 21px 18px;
}
