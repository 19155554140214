@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 32px/1.12 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 15px/1.68 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 12px/2.1 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 1063px;
  @include xmudder{
    display: none;
  }
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 28px 0px 27px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.flexRow {
  display: flex;
  width: 83.09%;
  position: relative;
  margin: 0px 6.52% 0px 10.39%;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 52px;
}
.image {
  width: 52px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 52px;
  margin: 4px 0px 13px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 60px;
}
.flexRow1 {
  @include flex-item(0, 1, 77px);
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 23px;
}
.icon {
  width: 23px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 23px;
  margin: 0px 0px 21px;
}
.desc {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex: 1 1 49px;
  min-height: 43px;
  margin: 1px 0px 0px 5px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 17px;
}
.flexRow2 {
  @include flex-item(0, 1, 110px);
  margin: 0px 0px 1px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 23px;
}
.icon1 {
  width: 23px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 23px;
  margin: 1px 0px 19px;
}
.desc1 {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex: 1 1 87px;
  min-height: 43px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 4px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;
}
.image1 {
  width: 24px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 24px;
  margin: 3px 0px 24px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
}
.title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  width: 59.66%;
  position: relative;
  flex-grow: 1;
  min-height: 173px;
  margin: 20px 29.95% 72px 10.39%;
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 38px 28px 0px;
}
.highlights {
  font: $font_type_1;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 26px 32px 30px 31px;
}
