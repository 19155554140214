@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 54px 54px 54px 54px;

$font_type_0: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 1em/0.9 "Sora", Helvetica, Arial, serif;
$font_type_5: 0.6428571428571429em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_6: 600 0.7142857142857143em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_7: 600 0.6428571428571429em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_8: 600 1em/0.9 "Sora", Helvetica, Arial, serif;
$font_type_9: 0.5em/1.8 "Sora", Helvetica, Arial, serif;
$font_type_10: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 73.58%;
  position: relative;
  flex-grow: 1;
  margin: 49px 10% 495px 16.42%;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.title {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
}
.title_box {
  width: 91.08%;
  position: relative;
  margin: 55px 8.68% 0px 0.24%;
}
.title_span0 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span1 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span2 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span3 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span4 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span5 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span6 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span7 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span8 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span9 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span10 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span11 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span12 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span13 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span14 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span15 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span16 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span17 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span18 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span19 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span20 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span21 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span22 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span23 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span24 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title_span25 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title1 {
  @include flex-column;
  width: 91.08%;
  position: relative;
  margin: 166px 8.21% 0px 0.71%;
}
.title2 {
  font: $font_type_2;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
}
.title2_box {
  position: relative;
  flex-grow: 1;
}
.title2_span0 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span1 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span2 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span3 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span4 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span5 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span6 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span7 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span8 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span9 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span10 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span11 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span12 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span13 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span14 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span15 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span16 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span17 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span18 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span19 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span20 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span21 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span22 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span23 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span24 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span25 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span26 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span27 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span28 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span29 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span30 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span31 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span32 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span33 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span34 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span35 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span36 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span37 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span38 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span39 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span40 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span41 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span42 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span43 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span44 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span45 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span46 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span47 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span48 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span49 {
  font: $font_type_7;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span50 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span51 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span52 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span53 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span54 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span55 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span56 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span57 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span58 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span59 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span60 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span61 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span62 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span63 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span64 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span65 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span66 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span67 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span68 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span69 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span70 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span71 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span72 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span73 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span74 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span75 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span76 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span77 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span78 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span79 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span80 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span81 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span82 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span83 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span84 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span85 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span86 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span87 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span88 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span89 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span90 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span91 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span92 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span93 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span94 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span95 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span96 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span97 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span98 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span99 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span100 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span101 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span102 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span103 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span104 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span105 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span106 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span107 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span108 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span109 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span110 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span111 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span112 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span113 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span114 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span115 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span116 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span117 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span118 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span119 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span120 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span121 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span122 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span123 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span124 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span125 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span126 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span127 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span128 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span129 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span130 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span131 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span132 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span133 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span134 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span135 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span136 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span137 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span138 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span139 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span140 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span141 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span142 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span143 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span144 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span145 {
  font: $font_type_9;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title2_span146 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_1;
  width: 74.85%;
  position: relative;
  margin: 207px 16.61% 0px 8.54%;
}
.flexCol2 {
  @include flex-column;
  width: 81.21%;
  position: relative;
  flex-grow: 1;
  margin: 68px auto 36px;
}
.title11 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
}
.title11_box {
  @include h-center-content;
  position: relative;
}
.title11_span0 {
  font: $font_type_10;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span1 {
  font: $font_type_10;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span2 {
  font: $font_type_10;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 25.16%;
  position: relative;
  margin: 16px auto 0px;
}
.subtitle2 {
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 39px;
}
