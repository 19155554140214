@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;

$font_type_0: 700 79px/0.79 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 1em/0.79 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_5: 0.6em/1.25 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexRow {
  display: flex;
  width: 93.06%;
  @include tn {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    column-gap: 0px;
    row-gap: 16px;
    width: 99.01%;
  }
  position: relative;
  margin: 59px auto 0px;
  @include xl {
    width: 94.7%;
  }
  @include lg {
    width: 95.97%;
  }
  @include md {
    width: 96.95%;
  }
  @include sm {
    width: 97.69%;
  }
  @include xs {
    width: 98.26%;
  }
  @include xxs {
    width: 98.69%;
  }
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 1 1 78px;
  @include tn {
    flex: 0 0 33.33333333333333%;
  }
}
.image3 {
  width: 78px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1178px;
  @include tn {
    flex: 0 0 33.33333333333333%;
  }
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 45px;
  @include tn {
    flex: 0 0 33.33333333333333%;
  }
}
.image2 {
  width: 45px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 45px;
  margin: 0px 0px 12px;
}
.wrapper {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  margin: 0px 0px 0px;
}
.hero_title1 {
  margin: 138px 56.32% 188px 14.65%;
  @include font-face($font_type_0, $color_type_0, -0.5px);
  @include lg {
    font-size: 68px;
    text-align: left;
  }
  @include md {
    font-size: 56px;
  }
  @include sm {
    font-size: 48px;
  }
  @include xs {
    font-size: 44px;
  }
  @include xxs {
    font-size: 36px;
  }
  position: relative;
  flex-grow: 1;
  min-height: 205px;
  width: 29.03%;
}
.hero_title1_span0 {
  font: $font_type_3;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: -0.5px;
}
.hero_title1_span1 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: -0.5px;
}
.hero_title {
  margin: 52px 17.85% 0px 23.89%;
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0px;
  @include lg {
    font-size: 36px;
    text-align: justify;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
  }
  @include tn {
    font-size: 20px;
  }
  position: relative;
  width: 58.26%;
  line-height: 70%;
}
.hero_title_span0 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title_span1 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0px;
}
.section1__item {
  @include flex-column;
  position: relative;
}
.btn {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 165px;
  height: 58px;
  cursor: pointer;
  position: relative;
  min-width: 162px;
  margin: 23px 0px 0px 530px;
  @include xs {
    margin: 23px 0px 0px 30% !important;
  }
}
.btn__text {
  @include v-center-content;
  justify-content: center;
  font: $font_type_2;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0px;
  width: fit-content;
  height: min-content;
  @include md {
    font-size: 16px;
    text-align: center;
    margin: 20px 36px;
  }
  position: relative;
  min-width: 0px;
  margin: 15px;
  @include tn {
    margin: 15px 4px;
  }
}
