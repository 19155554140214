@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 6px 6px 6px 6px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_4: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_5: 600 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_6: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_8: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_9: 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_10: 0.7777777777777778em/1.8 "Sora", Helvetica, Arial, serif;

.section7 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 80.9%;
  position: relative;
  flex-grow: 1;
  margin: 0px 8.61% 117px 10.49%;
  @include md {
    width: 90%;
  margin: 0px 5% 117px 5%;
}
}
.flexCol1 {
  @include flex-column;
  width: 88.5%;
  position: relative;
  margin: 0px 8.15% 0px 3.35%;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  @include lg {
    font-size: 36px;
    text-align: left;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
  }
  @include tn {
    font-size: 20px;
  }
  width: 69.25%;
  position: relative;
  min-height: 159px;
  margin: 0px 30.75% 0px 0%;
}
.highlights {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  margin: 0px 0px 0px 1px;
}
.flexCol2 {
  @include flex-column;
  width: 88.5%;
  position: relative;
  margin: 79px 8.07% 0px 3.43%;
}
.hero_title11 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  @include lg {
    font-size: 36px;
    text-align: left;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
  }
  @include tn {
    font-size: 20px;
  }
  position: relative;
}
.highlights1 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 127px;
  margin: 55px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  width: 88.5%;
  position: relative;
  margin: 104px 7.98% 0px 3.52%;
}
.highlights2 {
  margin: 55px 0px 0px 1px;
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 176px;
}
.highlights2_span0 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span1 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol4 {
  @include flex-column;
  width: 85.24%;
  position: relative;
  margin: 0px 11.42% 0px 3.35%;
}
.flexRow {
  display: flex;
  position: relative;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 44px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle1 {
  margin: 9px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
}
.subtitle1_span0 {
  font: $font_type_8;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_9;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow1 {
  display: flex;
  position: relative;
  margin: 21px 0px 0px;
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle11 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  margin: 9px 0px 0px;
}
.flexRow2 {
  display: flex;
  position: relative;
  margin: 30px 0px 0px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol5 {
  @include flex-column;
  width: 95.45%;
  position: relative;
  margin: 0px 4.55% 0px 0%;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image2 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 25px;
  margin: 5px 0px 15px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle12 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  min-height: 35px;
}
.flexCol6 {
  @include flex-column;
  width: 95.32%;
  position: relative;
  margin: 48px 0% 0px 4.68%;
}
.highlights3 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 92.08%;
  position: relative;
  margin: 0px 7.92% 0px 0%;
}
.rect10 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 19px 0px 0px 3px;
}
.flexCol7 {
  @include flex-column;
  width: 95.45%;
  position: relative;
  margin: 5% 4.55% 0px 0%;
}
.flexCol8 {
  @include flex-column;
  width: 92.45%;
  position: relative;
  margin: 0px 7.55% 0px 0%;
}
.flexRow__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights4 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  margin: 48px 0% 0px 5.06%;
}
.rect101 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  width: 95.05%;
  position: relative;
  min-height: 285px;
  margin: 20px 0% 0px 4.95%;
}
.flexCol9 {
  @include flex-column;
  width: 95.45%;
  position: relative;
  margin: 5% 4.55% 0px 0%;
}
.flexRow__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights5 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 87.77%;
  position: relative;
  margin: 48px 7.55% 0px 4.68%;
}
.rect102 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  width: 95.05%;
  position: relative;
  min-height: 285px;
  margin: 21.79px 0% 0px 4.95%;
}
.flexCol10 {
  @include flex-column;
  width: 95.45%;
  position: relative;
  margin: 5% 4.55% 0px 0%;
}
.flexRow__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights6 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 92.08%;
  position: relative;
  margin: 0px 7.92% 0px 0%;
}
.rect103 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 6px 0px 0px 3px;
}
.flexCol11 {
  @include flex-column;
  width: 95.19%;
  position: relative;
  margin: 5% 4.81% 0px 0%;
}
.flexRow__item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights7 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 88.01%;
  position: relative;
  margin: 48px 7.3% 0px 4.69%;
}
.rect104 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  width: 95.31%;
  position: relative;
  min-height: 285px;
  margin: 8px 0% 0px 4.69%;
}
.flexCol12 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 144px 8.41% 0px 4.46%;
}
.title {
  font: $font_type_1;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: justify;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 35px;
}
.highlights8 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 62px;
  margin: 21px 39px 0px 0px;
}
.flexCol13 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 18px 8.41% 0px 4.46%;
}
.highlights9 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 11px 20px 0px 0px;
  }
  @include lg {
    margin: 11px 32px 0px 0px;
  }
  @include md {
    margin: 11px 28px 0px 0px;
  }
  @include xxs {
    margin: 11px 16px 0px 0px;
  }
  position: relative;
  min-height: 110px;
  margin: 11px 39px 0px 0px;
}
.flexRow3 {
  display: flex;
  width: 95.36%;
  @include xl {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    row-gap: 16px;
  }
  position: relative;
  margin: 7px 0% 0px 4.64%;
}
.flexRow3__item {
  @include flex-column;
  position: relative;
  flex: 1 1 556px;
  @include xl {
    flex: 0 0 calc(1 / 2 * 100% - 0px / 2);
  }
}
.flexCol14 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.content_box7 {
  @include flex-column;
  background-color: $color_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
}
.highlights31 {
  margin: 6px 27.52% 6px 4.68%;
  font: $font_type_5;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  flex-grow: 1;
  width: 67.81%;
}
.highlights31_span0 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span1 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span3 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span4 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span5 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights31_span6 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box8 {
  @include flex-column;
  background-color: $color_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
}
.highlights32 {
  margin: 16px 17.63% 12px 2.7%;
  font: $font_type_5;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  flex-grow: 1;
  width: 79.68%;
}
.highlights32_span0 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span1 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span3 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span4 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span5 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights32_span6 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow3__item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 555px;
  @include xl {
    flex: 0 0 calc(1 / 2 * 100% - 0px / 2);
  }
}
.content_box9 {
  @include flex-column;
  background-color: $color_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
}
.highlights33 {
  margin: 6px 24.14% 15px 5.59%;
  font: $font_type_5;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  flex-grow: 1;
  width: 70.27%;
}
.highlights33_span0 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span1 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span3 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span4 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span5 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights33_span6 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box10 {
  @include flex-column;
  background-color: $color_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
}
.highlights34 {
  margin: 14px 13px 7px 12px;
  font: $font_type_5;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  flex-grow: 1;
  @include lg {
    margin: 14px 8px 7px;
  }
  @include xs {
    margin: 14px 4px 7px;
  }
}
.highlights34_span0 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span1 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span3 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span4 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span5 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights34_span6 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol15 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 98px 8.41% 0px 4.46%;
}
.highlights10 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 11px 20px 0px 0px;
  }
  @include lg {
    margin: 11px 32px 0px 0px;
  }
  @include md {
    margin: 11px 28px 0px 0px;
  }
  @include xxs {
    margin: 11px 16px 0px 0px;
  }
  position: relative;
  min-height: 748px;
  margin: 11px 39px 0px 0px;
}
.flexCol16 {
  @include flex-column;
  width: 87.81%;
  position: relative;
  margin: 49px 4.55% 0px 4.46%;
}
.title1 {
  font: $font_type_1;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    margin: 0px 0px 0px 4px;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 35px;
  margin: 0px 0px 0px 8px;
}
.flexCol17 {
  @include flex-column;
  @include lg {
    margin: 21px 24px 0px 0px;
  }
  @include md {
    margin: 21px 20px 0px 0px;
  }
  @include sm {
    margin: 21px 16px 0px 0px;
  }
  @include xxs {
    margin: 21px 12px 0px 0px;
  }
  position: relative;
  margin: 21px 30px 0px 0px;
}
.flexRow__item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow__spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow1__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon1 {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 44px;
  margin: 0px 0px 2px;
}
.flexRow2__spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle13 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  margin: 11px 0px 0px;
}
.flexRow4 {
  display: flex;
  position: relative;
  margin: 28px 0px 0px;
}
.flexRow4__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow4__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol18 {
  @include flex-column;
  width: 88.24%;
  position: relative;
  margin: 124px 4.72% 0px 7.04%;
}
.flexCol19 {
  @include flex-column;
  position: relative;
}
.flexRow__item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights11 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 161px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol20 {
  @include flex-column;
  position: relative;
  margin: 89px 0px 0px;
}
.flexRow__item8 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer8 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights12 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 268px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow__item9 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer9 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights13 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 268px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow__item10 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer10 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights14 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 968px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol21 {
  @include flex-column;
  position: relative;
  margin: 93px 0px 0px;
}
.flexRow__item11 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights15 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 212px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow__item12 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer12 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights16 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 106px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow__item13 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer13 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights17 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 328px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol22 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 36px 1.37% 0px 4.46%;
}
.highlights18 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 172px;
  margin: 21px 39px 0px 0px;
}
.flexCol23 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 60px 1.37% 0px 4.46%;
}
.highlights19 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  margin: 21px 39px 0px 0px;
}
.flexCol24 {
  @include flex-column;
  width: 87.12%;
  position: relative;
  margin: 50px 1.37% 0px 4.46%;
}
.highlights20 {
  margin: 21px 39px 0px 0px;
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: -0.09999999999999998px;
  @include sm {
    font-size: 12px;
    text-align: justify;
    margin: 21px 20px 0px 0px;
  }
  position: relative;
  min-height: 76px;
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
}
.highlights20_span0 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights20_span1 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights20_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexRow5 {
  display: flex;
  position: relative;
  margin: 45px 0px 0px;
}
.flexRow5__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow5__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow1__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol25 {
  @include flex-column;
  width: 88.24%;
  position: relative;
  margin: 124px 2.06% 0px 4.46%;
}
.flexRow__item14 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer14 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights21 {
  margin: 48px 0% 0px 5.06%;
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 698px;
  width: 94.94%;
}
.highlights21_span0 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span1 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span3 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span4 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span5 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span6 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span7 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span8 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span9 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span10 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span11 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span12 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span13 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span14 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span15 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span16 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span17 {
  font: $font_type_10;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21_span18 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol26 {
  @include flex-column;
  width: 88.24%;
  position: relative;
  margin: 93px 2.06% 0px 4.46%;
}
.flexRow__item15 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer15 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights22 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 236px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow__item16 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer16 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights23 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  width: 94.94%;
  position: relative;
  min-height: 101px;
  margin: 48px 0% 0px 5.06%;
}
