@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 32px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.flexRow {
  display: flex;
  width: 93.06%;
  @include xl {
    width: 94.7%;
  }
  @include lg {
    width: 95.97%;
  }
  @include md {
    width: 96.95%;
  }
  @include sm {
    width: 97.69%;
  }
  @include xs {
    width: 98.26%;
  }
  @include xxs {
    width: 98.69%;
  }
  @include tn {
    width: 99.01%;
  }
  position: relative;
  margin: 0px auto;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image1 {
  width: 78px;
  height: min-content;
  aspect-ratio: 1.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1188px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image3 {
  width: 45px;
  height: min-content;
  aspect-ratio: 1.32;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 45px;
  margin: 0px 0px 7px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  display: flex;
  width: 77.22%;
  @include lg {
    width: 81.89%;
  }
  @include md {
    width: 85.77%;
  }
  @include sm {
    width: 88.93%;
  }
  @include xs {
    width: 91.47%;
  }
  @include xxs {
    width: 93.46%;
  }
  @include tn {
    width: 95.01%;
  }
  position: relative;
  flex-grow: 1;
  margin: 70px auto 153px;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  @include lg {
    font-size: 40px;
    text-align: left;
  }
  @include md {
    font-size: 36px;
  }
  @include sm {
    font-size: 32px;
  }
  @include xs {
    font-size: 28px;
  }
  @include xxs {
    font-size: 24px;
  }
  position: relative;
  flex: 0 1 478px;
  min-height: 173px;
  margin: 0px 0px 30px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 200px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
  margin: 27px 0px 0px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image {
  width: 82px;
  height: min-content;
  aspect-ratio: 1.09;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  @include lg {
    aspect-ratio: 1.09;
    margin: 0px 28px 0px 0px;
  }
  @include md {
    margin: 0px 24px 0px 0px;
  }
  @include sm {
    margin: 0px 20px 0px 0px;
  }
  @include xs {
    margin: 0px 16px 0px 0px;
  }
  @include tn {
    margin: 0px 12px 0px 0px;
  }
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title {
  @include font-face($font_type_1, $color_type_1, 0.5px);
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  position: relative;
  min-height: 50px;
  margin: 51px 0px 0px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
  margin: 27px 0px 0px;
}
.flexCol3__item {
  @include flex-column;
  position: relative;
}
.image2 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.09;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 72.99%;
  @include lg {
    width: 78.27%;
  }
  @include md {
    width: 82.76%;
  }
  @include sm {
    width: 86.49%;
  }
  @include xs {
    width: 89.51%;
  }
  @include xxs {
    width: 91.92%;
  }
  @include tn {
    width: 93.82%;
  }
  position: relative;
  margin: 21px auto 0px;
}
.subtitle {
  @include font-face($font_type_2, $color_type_2, 0.5px);
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  width: 83.06%;
  position: relative;
  flex-grow: 1;
  margin: 54px 8.94% 33px 7.99%;
}
.group {
  @include flex-column;
  width: 75%;
  position: relative;
  min-height: 544px;
  margin: 23px 10.9% 0px 14.1%;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol4 {
  @include flex-column;
  width: 77.65%;
  position: relative;
  flex-grow: 1;
  margin: 79px 14.45% 53px 7.9%;
}
.medium_title2 {
  @include font-face($font_type_1, $color_type_2, 0.5px);
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  width: 83.75%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.25% 0px 0%;
}
.subtitle1 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  min-height: 280px;
  margin: 21px 0px 0px 2px;
}
.image21 {
  width: 147px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0px;
  bottom: 397px;
  min-width: 147px;
  right: 0px;
}
