@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 30px/0.84 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 25px/1.28 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 16px/1.57 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include xmudder{
    display: none;
  }
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 69px 16px 180px 22px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  margin: 0px 18px 0px 15px;
}
.medium_title {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  margin: 0px 7px 0px 9px;
}
.group {
  @include flex-column;
  height: 441px;
  position: relative;
  margin: 42px 0px 0px;
}
.content_box11 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 29px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 45px 30px 27px 24px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image5 {
  width: 77px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 77px;
  margin: 0px auto;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 30px 0px 0px;
}
.subtitle {
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 87px;
}
.subtitle_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  margin: 7px 0px 0px;
}
.content_box2 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 392px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title2 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 12px 10px 10px;
}
.group1 {
  @include flex-column;
  height: 464px;
  position: relative;
  margin: 91px 18px 0px 14px;
}
.content_box12 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 30px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol4 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 73px 22px 14px 27px;
}
.flexCol4__item {
  @include flex-column;
  position: relative;
}
.image6 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.flexCol5 {
  @include flex-column;
  position: relative;
  margin: 39px 0px 0px;
}
.subtitle1 {
  margin: 0px 17px 0px 6px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 121px;
}
.subtitle1_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights21 {
  @include h-center-content;
  font: $font_type_3;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 115px;
}
.content_box21 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 415px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title21 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 13px 10px 9px;
}
.flexCol6 {
  @include flex-column;
  position: relative;
  margin: 97px 14px 0px 12px;
}
.medium_title1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  margin: 0px 0px 0px 2px;
}
.group2 {
  @include flex-column;
  height: 452px;
  position: relative;
  margin: 48px 2px 0px 0px;
}
.content_box13 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 32px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol7 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 50px 0px 0px 3px;
}
.flexCol7__item {
  @include flex-column;
  position: relative;
}
.image7 {
  width: 84px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 84px;
  margin: 0px auto;
}
.flexCol8 {
  @include flex-column;
  position: relative;
  margin: 21px 0px 0px;
}
.subtitle2 {
  margin: 0px 38px 0px 18px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 136px;
}
.subtitle2_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle2_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box22 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 403px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title22 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 12px 10px 8px;
}
.medium_title2 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  min-height: 204px;
}
.group3 {
  @include flex-column;
  height: 429px;
  position: relative;
  margin: 4px 14px 0px 12px;
}
.content_box14 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 25px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol9 {
  @include flex-column;
  width: 71.43%;
  position: relative;
  flex-grow: 1;
  margin: 66px auto 29px;
}
.flexCol9__item {
  @include flex-column;
  position: relative;
}
.icon4 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.subtitle3 {
  margin: 26px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 196px;
}
.subtitle3_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box23 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 380px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title23 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 14px 10px 8px;
}
.flexCol10 {
  @include flex-column;
  position: relative;
  margin: 79px 8px 0px 2px;
}
.medium_title3 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
}
.group4 {
  @include flex-column;
  height: 436px;
  position: relative;
  margin: 17px 11px 0px 15px;
}
.content_box15 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 26px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol11 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 60px 38px 27px 40px;
}
.flexCol11__item {
  @include flex-column;
  position: relative;
}
.image8 {
  width: 49px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 49px;
  margin: 0px auto;
}
.subtitle4 {
  margin: 33px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
}
.subtitle4_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle4_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box24 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 387px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title24 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 12px 11px 3px 12px;
}
.group5 {
  @include flex-column;
  height: 413px;
  position: relative;
  margin: 53px 19px 0px 17px;
}
.content_box16 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 23px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol12 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 53px 29px 22px 30px;
}
.flexCol12__item {
  @include flex-column;
  position: relative;
}
.image9 {
  width: 79px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 79px;
  margin: 0px auto;
}
.subtitle5 {
  margin: 27px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 196px;
}
.subtitle5_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle5_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box25 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 364px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title25 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 14px 10px 10px;
}
.flexCol13 {
  @include flex-column;
  position: relative;
  margin: 18px 32px 0px 10px;
}
.medium_title4 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  margin: 0px 25px 0px 34px;
}
.group6 {
  @include flex-column;
  height: 426px;
  position: relative;
  margin: 9px 0px 0px;
}
.content_box17 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 28px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.image10 {
  width: 104px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 104px;
  margin: 61px auto 238px;
}
.content_box26 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 377px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.flexCol14 {
  @include flex-column;
  position: relative;
  margin: 27px 16px 0px 20px;
}
.medium_title5 {
  @include v-center-content;
  @include font-face($font_type_1, $color_type_2, 0px);
  position: relative;
  margin: 0px 19px 0px 33px;
}
.group7 {
  @include flex-column;
  height: 457px;
  position: relative;
  margin: 14px 0px 0px;
}
.content_box18 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 27px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.flexCol15 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 70px 1px 32px 0px;
}
.flexCol15__item {
  @include flex-column;
  position: relative;
}
.image11 {
  width: 72px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 72px;
  margin: 0px auto;
}
.subtitle6 {
  margin: 72px 0px 0px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 172px;
}
.subtitle6_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle6_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle6_span2 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box27 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 408px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title26 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 14px 9px 6px 11px;
}
.flexCol16 {
  @include flex-column;
  position: relative;
  margin: 79px 16px 0px;
}
.medium_title6 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  margin: 0px 5px 0px 0px;
}
.group8 {
  @include flex-column;
  height: 674px;
  position: relative;
  margin: 47px 0px 0px 4px;
}
.content_box19 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 23px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.subtitle7 {
  margin: 51px 37px 0px 28px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  min-height: 600px;
}
.subtitle7_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle7_span1 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box10 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 49px;
  position: absolute;
  top: 0px;
  bottom: 629px;
  left: 0px;
  right: 0px;
  min-width: 49px;
  margin: 0px auto;
}
.title27 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex-grow: 1;
  margin: 12px 8px 6px 7px;
}
