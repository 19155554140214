@use "utils" as *;

$color_type_0: rgb(253, 194, 12);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);
$color_type_3: rgb(55, 65, 81);

$border_radius_type_0: 6px 6px 6px 6px;

$font_type_0: 700 30px/1.08 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 22px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 14px/1.42 "Sora", Helvetica, Arial, serif;

.root {
  @include flex-column;
  position: relative;
  margin-top: 4%;
  width: 90vw;
  align-self: center;
  @include xmudder {
    display: none;
  }
}
.builtlogo{
  width: 30vw;
  align-self: center;
  margin: 10% 0% 0% 0%;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 1px 29px 3px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  position: relative;
}
.title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_0, 0px);
  width: 100%;
  position: relative;
  flex-grow: 1;
  min-height: 128px;
  margin: 0px 39.23% 5px 0%;
  line-height: 100%;
}
.flexCol1 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 0px auto 0px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
}
.text {
  @include font-face($font_type_2, $color_type_3, 0px);
  position: relative;
}
.rect1 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  height: 38px;
  position: relative;
  margin: 5px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 12px 0px 0px;
  width: 100%;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 24px 0px 0px;
}
.subtitle {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  width: 110px;
  position: relative;
  flex-grow: 1;
  min-width: 110px;
  margin: 8px auto 10px;
}
