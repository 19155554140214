@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.group {
  @include flex-column;
  width: 75%;
  position: relative;
  flex-grow: 1;
  min-height: 544px;
  margin: 36px 10.9% 0px 14.1%;
  @include md {
    width: 95%;
  min-height: 644px;
  margin: 36px 5% 0px 5%;
}
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol {
  @include flex-column;
  width: 77.65%;
  position: relative;
  flex-grow: 1;
  margin: 79px 14.45% 53px 7.9%;
}
.medium_title {
  @include font-face($font_type_0, $color_type_1, 0.5px);
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  width: 83.75%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.25% 0px 0%;
}
.subtitle {
  font: $font_type_1;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  min-height: 280px;
  margin: 21px 0px 0px 2px;
}
.image1 {
  width: 147px;
  height: min-content;
  aspect-ratio: 1;
  background: var(--src) center center / cover no-repeat;
  position: absolute;
  top: 0px;
  bottom: 397px;
  min-width: 147px;
  right: 0px;
}
