@use "utils" as *;

$color_type_0: rgb(107, 111, 130);
$color_type_1: rgb(238, 238, 238);
$color_type_2: rgb(255, 255, 255);
$color_type_3: #114AA0;
$color_type_4: #ffc700;

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 39px 39px 0px 0px;
$border_radius_type_2: 39px 39px 39px 39px;

$font_type_0: 600 32px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 18px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 14px/1.8 "Open Sans", Helvetica, Arial, serif;

.root {
  @include flex-column;
  margin-top: 5%;
  position: relative;
  width: 50vw;
  height: auto;
  align-self: center;
  @include md {
    display: none;
  }
}
.group {
  @include flex-column;
  height: 60vh;
  position: relative;
  padding-bottom: 10%;
}
.flexCol {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.rect {
  background-color: $color_type_3;
  border-radius: $border_radius_type_2;
  height: 100vh;
  box-shadow: 0px 2px 18px 0px rgba(49, 53, 72, 0.098);
  position: relative;
}
.line {
  background-color: $color_type_1;
  height: 1px;
  position: relative;
  margin: 54px 2px 0px 0px;
}
.flexCol1 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 91px;
  left: 0px;
  right: 0px;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
  width: 100%;
  height: 40vh;
}

.image1 {
  border-radius: $border_radius_type_1;
  width: 100%;
  height: 40vh;
  vertical-align: top;
  object-fit: cover;
  object-position: center top;
  position: relative;
}
.paragraph {
  @include font-face($font_type_0, $color_type_2, 0.5px);
  position: relative;
  min-height: 51px;
  margin: 20px 6.14% 0% 3.86%;
}
.paragraph1 {
  @include font-face($font_type_1, $color_type_2, 1.0px);
  width: 90%;
  position: relative;
  margin: 20px 6.14% 0% 3.86%;
}
.paragraph2 {
  @include font-face($font_type_1, $color_type_2, 1.0px);
  width: 90%;
  position: relative;
  margin: 0% 6.14% 0% 3.86%;
}
.box {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_0;
  width: 21.93%;
  position: relative;
  margin: 20px 6.14% 0% 71.93%;
}
.flexRow {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 10px 5px 10px 15px;
}
.flexRowBut {
  border-width: 0px !important;
  background-color: transparent;
}
.subtitle {
  @include font-face($font_type_2, $color_type_3, 0px);
  position: relative;
  flex: 0 0 auto;
  min-width: 67px;
  margin-bottom: 0%;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}
.image {
  width: 15px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 12px;
  margin: 5px 0px 3px 0px;
  align-self: center;
}
