@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 400 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_6: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  position: relative;
  margin: 59px 0px 0px;
}
.flexRow {
  @include v-center-content;
  width: 93.06%;
  position: relative;
  margin: 0px auto;
}
.flexRow__cell {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image {
  width: 78px;
  height: auto;
  aspect-ratio: 1.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1188px;
}
.flexRow__cell1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image2 {
  width: 45px;
  height: auto;
  aspect-ratio: 1.41;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 45px;
  margin: 9px 0px 0px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  @include v-center-content;
  width: 82.22%;
  position: relative;
  flex-grow: 1;
  margin: 34px auto 94px;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  position: relative;
  flex: 0 1 539px;
  min-height: 298px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 119px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
  margin: 64px 0px 89px;
}
.flexCol1__cell {
  @include flex-column;
  position: relative;
}
.icon1 {
  width: 82px;
  height: auto;
  aspect-ratio: 1.02;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title1 {
  @include font-face($font_type_2, $color_type_1, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 15px 0px 0px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
  margin: 64px 0px 89px;
}
.flexCol2__cell {
  @include flex-column;
  position: relative;
}
.icon11 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.02;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.flexCol3 {
  @include flex-column;
  width: 75%;
  position: relative;
  margin: 51px 11.32% 70px 13.68%;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_1, $color_type_2, 0px);
  position: relative;
  margin: 0px 13px;
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 58px 29px 0px 0px;
}
.subtitle {
  @include font-face($font_type_3, $color_type_2, 0.5px);
}
.subtitle_box {
  width: 87.35%;
  position: relative;
  flex-grow: 1;
  min-height: 301px;
  margin: 57px 4.85% 35px 7.8%;
}
.subtitle_span0 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.group {
  @include flex-column;
  position: relative;
  min-height: 448px;
  margin: 81px 0px 0px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol4 {
  @include flex-column;
  width: 77.46%;
  position: relative;
  flex-grow: 1;
  margin: 65px 14.64% 29px 7.9%;
}
.medium_title {
  @include font-face($font_type_2, $color_type_2, 0.5px);
  width: 83.96%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.04% 0px 0%;
}
.subtitle1 {
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 8px 0px 0px;
}
.image1 {
  width: 13.61%;
  height: auto;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0px;
  bottom: 301px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 86.39%;
}
