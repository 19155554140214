@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(253, 194, 12);
$color_type_2: rgb(255, 255, 255);
$color_type_3: rgb(28, 62, 146);

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 16px/1.57 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 13px/1.23 "Sora", Helvetica, Arial, serif;
$font_type_4: 600 1em/0.93 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_6: 0.6em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_7: 0.5em/1.25 "Sora", Helvetica, Arial, serif;
$font_type_8: 0.4em/1.25 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  @include md {
    display: none;
  }
}
.content_box {
  @include flex-column;
  background-color: $color_type_3;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 28px;
}
.flexCol {
  @include flex-column;
  width: 88.06%;
  position: relative;
  flex-grow: 1;
  margin: 59px 8.47% 52px 3.47%;
}
.flexRow {
  display: flex;
  position: relative;
  @include xs{
    display: block;
  }
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image3 {
  width: 78px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 78px;
  margin: 0px 0px 8px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 537px;
}
.flexRow1 {
  @include flex-item(0, 1, 348px);
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 37px;
  border-width: 0px !important;
  background-color: $color_type_3;
}
.image8 {
  width: 37px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 37px;
  margin: 1px 0px 13px;
}
.highlights1 {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  flex: 1 1 105px;
  min-height: 43px;
  margin: 6px 0px 0px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 41px;
  border-width: 0px !important;
  background-color: $color_type_3;
}
.icon7 {
  width: 41px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 41px;
  margin: 0px 0px 8px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.flexRow2 {
  display: flex;
  width: 93.14%;
  position: relative;
  margin: 49px 0% 0px 6.86%;
  @include xs{
    display: block;
  }
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 478px;
  margin: 0px 0px 11px;
}
.flexCol1_a {
  @include flex-column;
  position: relative;
  flex: 0 1 800px;
  margin: 0px 0px 11px;
}
.hero_title {
  @include font-face($font_type_0, $color_type_0, 0px);
  position: relative;
}
.hero_title_span0 {
  font: $font_type_4;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.flexCol2 {
  @include flex-column;
  width: 34.1%;
  position: relative;
  margin: 11px 43.51% 0px 22.38%;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  margin: 0px 23px 0px 11px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 23px 0px 0px;
}
.text {
  @include v-center-content;
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
  margin: 0px 0px 0px 0px;
  align-self: center;
}
.flexCol3__item {
  @include flex-column;
  position: relative;
}
.image7 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  margin: 9px 0px 0px;
}
.text1 {
  @include v-center-content;
  @include font-face($font_type_3, $color_type_2, 0px);
  position: relative;
  margin: 10% 0px 0px 0px;
  align-self: center;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 50px;
}
.hero_title2 {
  margin: 0px 0px 0px;
  font: $font_type_1;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0px;
  position: relative;
  flex: 1 1 653px;
}
.hero_title2_span0 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title2_span1 {
  font: $font_type_8;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title2_span2 {
  font: $font_type_7;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title2_span3 {
  font: $font_type_8;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0px;
}
