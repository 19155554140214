@use "utils" as *;

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgb(28, 62, 146);

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 26px/0.96 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 620px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 43px;
}
.flexRow {
  display: flex;
  width: 93.06%;
  position: relative;
  margin: 0px auto;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image1 {
  width: 78px;
  height: min-content;
  aspect-ratio: 1.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1188px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image2 {
  width: 45px;
  height: min-content;
  aspect-ratio: 1.32;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 45px;
  margin: 0px 0px 7px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_1;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  display: flex;
  width: 77.22%;
  position: relative;
  flex-grow: 1;
  margin: 70px auto 153px;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_0, -0.5px);
  position: relative;
  flex: 0 1 478px;
  min-height: 173px;
  margin: 0px 0px 30px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 200px;
}
.flexRow2 {
  @include flex-item(0, 1, 423px);
  margin: 26px 0px 0px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
}
.image {
  width: 82px;
  height: min-content;
  aspect-ratio: 0.94;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title {
  @include font-face($font_type_1, $color_type_0, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 40px 0px 0px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
  margin: 3px 0px 0px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.icon {
  width: 100%;
  height: 100%;
  aspect-ratio: 0.96;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
