@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 22px 22px 22px 22px;
$border_radius_type_1: 27px 27px 27px 27px;

$font_type_0: 700 21px/1.58 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 16px/1.57 "Sora", Helvetica, Arial, serif;

.root {
  @include flex-column;
  position: relative;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 50%;
  }
  
  .modal-main {
    position:fixed;
    width: 80%;
    height: 20%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

.wrapper {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_1;
  position: relative;
  flex-grow: 1;
}
.flexRow {
  display: flex;
  width: 83.57%;
  height: 10%;
  position: relative;
  flex-grow: 1;
  margin: 41px 5.61% 0px 10.82%;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 1;
  margin: 2px 0px 0px;
  padding: 0%;
  flex-wrap: wrap;
  justify-content: center;
}
.subtitle {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 138px;
  margin: 0px 3px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 15px 0px 0px;
}
.flexRow1 {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 26px 27px 14px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 1;
}
.flexCol1__item {
  @include flex-column;
  position: relative;
}
.icon1 {
  width: 110px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 110px;
  margin: 0px 25px 0px 26px;
}

.iconbut {
  width: 110px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 110px;
  margin: 0px 50px 0px 26px;
  border-width: 0px !important;
}
.iconbutXs {
  width: 110px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 110px;
  margin: 0px 0px 0px 0px;
  border-width: 0px !important;
}
.highlights {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 43px;
  margin: 21px 0px 0px 25%;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 1;
}
.flexCol1__item1 {
  @include flex-column;
  position: relative;
}
.icon11 {
  width: 110px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 110px;
  margin: 0px 24px 0px 27px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 1;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 1;
}
.icon {
  width: 24px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 24px;
  margin: 0px 0px 0px;
  border-width: 0px;
}

.iconXs {
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 24px;
  margin: 40px auto 0px auto;
  border-width: 0px !important;
}
