@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 1180px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 69px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.flexRow {
  display: flex;
  width: 93.06%;
  position: relative;
  margin: 0px auto;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image1 {
  width: 78px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1188px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image2 {
  width: 45px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 45px;
  margin: 0px 0px 7px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  display: flex;
  width: 77.22%;
  position: relative;
  flex-grow: 1;
  margin: 70px auto 153px;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  position: relative;
  flex: 0 1 478px;
  min-height: 173px;
  margin: 0px 0px 30px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 200px;
}
.flexRow2 {
  @include flex-item(0, 1, 423px);
  margin: 27px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image {
  width: 82px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title {
  @include font-face($font_type_2, $color_type_1, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 51px 0px 0px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
}
.flexCol3__item {
  @include flex-column;
  position: relative;
}
.image3 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.flexCol4 {
  @include flex-column;
  width: 76.6%;
  position: relative;
  margin: 76px 9.44% 0px 13.96%;
  @include md {
    width: 92%;
    margin: 76px 5% 0px 5%;
}
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_1, $color_type_2, 0px);
  position: relative;
  margin: 0px 9px;
}
.group {
  @include flex-column;
  position: relative;
  min-height: 408px;
  @include md {
    min-height: 700px;
  }
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 95.29%;
  position: absolute;
  top: 69px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 4.71% 0px 0%;
  @include md {
    width: 100%;
    position: absolute;
    top: 69px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0px 4.71% 0px 0%;
  }
}
.subtitle {
  @include font-face($font_type_3, $color_type_2, 0.5px);
  width: 83.06%;
  position: relative;
  flex-grow: 1;
  margin: 61px 9.71% 27px 7.23%;
  @include md {
    width: 90%;
    position: relative;
    flex-grow: 1;
    margin: 25% 5% 10% 5%;
  }
}
.image31 {
  width: 147px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: absolute;
  top: 0px;
  bottom: 261px;
  min-width: 147px;
  right: 0px;
}
