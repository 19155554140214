@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_3: 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexRow {
  @include xl {
    width: 94.7%;
  }
  @include lg {
    width: 95.97%;
  }
  @include md {
    width: 96.95%;
  }
  @include sm {
    width: 97.69%;
  }
  @include xs {
    width: 98.26%;
  }
  @include xxs {
    width: 98.69%;
  }
  @include tn {
    width: 99.01%;
  }
  display: flex;
  align-items: center;
  width: 93.06%;
  position: relative;
  margin: 54px auto 0px;
}
.flexRow__cell {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image {
  width: 78px;
  height: auto;
  aspect-ratio: 1.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 78px;
  margin: 5px 0px 0px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1188px;
}
.flexRow__cell1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image5 {
  width: 45px;
  height: auto;
  aspect-ratio: 1.32;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 45px;
  margin: 0px 0px 12px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  @include xl {
    width: 86.05%;
  }
  @include lg {
    width: 89.16%;
  }
  @include md {
    width: 91.64%;
  }
  @include sm {
    width: 93.6%;
  }
  @include xs {
    width: 95.12%;
  }
  @include xxs {
    width: 96.3%;
  }
  @include tn {
    width: 97.2%;
  }
  display: flex;
  align-items: center;
  width: 82.22%;
  position: relative;
  flex-grow: 1;
  margin: 34px auto 94px;
}
.hero_title {
  @include lg {
    font-size: 40px;
    text-align: left;
  }
  @include md {
    font-size: 36px;
  }
  @include sm {
    font-size: 32px;
  }
  @include xs {
    font-size: 28px;
  }
  @include xxs {
    font-size: 24px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  position: relative;
  flex: 0 1 541px;
  min-height: 298px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 117px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
  margin: 45px 0px 89px;
}
.flexCol__cell {
  @include flex-column;
  position: relative;
}
.image4 {
  @include lg {
    margin: 0px 28px 0px 0px;
  }
  @include md {
    margin: 0px 24px 0px 0px;
  }
  @include sm {
    margin: 0px 20px 0px 0px;
  }
  @include xs {
    margin: 0px 16px 0px 0px;
  }
  @include tn {
    margin: 0px 12px 0px 0px;
  }
  width: 82px;
  height: auto;
  aspect-ratio: 1.19;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title1 {
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  @include font-face($font_type_2, $color_type_1, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 45px 0px 0px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
  margin: 45px 0px 89px;
}
.flexCol1__cell {
  @include flex-column;
  position: relative;
}
.image3 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.34;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.medium_title11 {
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  @include font-face($font_type_2, $color_type_1, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 53px 0px 0px;
}
.flexCol2 {
  @include lg {
    width: 78.27%;
  }
  @include md {
    width: 82.76%;
  }
  @include sm {
    width: 86.49%;
  }
  @include xs {
    width: 89.51%;
  }
  @include xxs {
    width: 91.92%;
  }
  @include tn {
    width: 93.82%;
  }
  @include flex-column;
  width: 72.99%;
  position: relative;
  margin: 76px auto 0px;
}
.hero_title1 {
  @include lg {
    font-size: 36px;
    text-align: left;
    margin: 0px 8px;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
    margin: 0px 4px;
  }
  @include tn {
    font-size: 20px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_1, $color_type_2, 0px);
  position: relative;
  margin: 0px 13px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 29px 0px 0px;
}
.subtitle {
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  @include font-face($font_type_3, $color_type_2, 0.5px);
  width: 87.35%;
  position: relative;
  flex-grow: 1;
  margin: 50px 5.33% 33px 7.33%;
}
.group {
  @include flex-column;
  width: 75%;
  position: relative;
  min-height: 491px;
  margin: 52px 10.9% 55px 14.1%;
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol3 {
  @include flex-column;
  width: 77.65%;
  position: relative;
  flex-grow: 1;
  margin: 79px 14.45% 0px 7.9%;
}
.medium_title {
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  @include font-face($font_type_2, $color_type_2, 0.5px);
  width: 83.75%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.25% 0px 0%;
}
.subtitle1 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_4;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 280px;
  margin: 21px 0px 0px 2px;
}
.image1 {
  width: 13.61%;
  height: auto;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0px;
  bottom: 344px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 86.39%;
}
