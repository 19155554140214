@use "utils" as *;

$color_type_0: rgb(28, 62, 146);

$font_type_0: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_3: 18px/1.4 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 10871px;
}
.flexCol {
  @include flex-column;
  width: 77.71%;
  position: relative;
  flex-grow: 1;
  margin: 94px 13.82% 358px 8.47%;
}
.flexCol1 {
  @include flex-column;
  width: 92.05%;
  position: relative;
  margin: 0px 0% 0px 7.95%;
}
.title {
  font: $font_type_0;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 35px;
  margin: 0px 15px 0px 0px;
}
.highlights {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 184px;
  margin: 21px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  width: 92.05%;
  position: relative;
  margin: 60px 0% 0px 7.95%;
}
.highlights1 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 11px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  width: 92.05%;
  position: relative;
  margin: 43px 0% 0px 7.95%;
}
.highlights2 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 87px;
  margin: 21px 0px 0px;
}
.highlights3 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 142px;
  margin: 21px 0px 0px;
}
.flexCol4 {
  @include flex-column;
  width: 92.05%;
  position: relative;
  margin: 62px 0% 0px 7.95%;
}
.highlights4 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 266px;
  margin: 21px 0px 0px;
}
.title1 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 90.71%;
  position: relative;
  min-height: 35px;
  margin: 72px 1.34% 0px 7.95%;
}
.flexRow {
  display: flex;
  position: relative;
  margin: 31px 35px 0px;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon1 {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 44px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle {
  font: $font_type_1;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  margin: 9px 0px 0px;
}
.flexRow1 {
  display: flex;
  position: relative;
  margin: 21px 35px 0px;
}
.flexRow1__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2 {
  display: flex;
  position: relative;
  margin: 30px 35px 0px;
}
.flexRow2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow3 {
  display: flex;
  position: relative;
  margin: 37px 35px 0px;
}
.flexRow3__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow3__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow1__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow2__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow2__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow3__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow3__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow4 {
  display: flex;
  position: relative;
  margin: 39px 35px 0px;
}
.flexRow4__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow4__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexRow3__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow3__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.flexCol5 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 142px 8.13% 0px 0%;
}
.flexRow5 {
  display: flex;
  position: relative;
}
.flexRow5__item {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image3 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 25px;
  margin: 5px 0px 15px;
}
.flexRow5__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle1 {
  font: $font_type_1;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  min-height: 35px;
}
.highlights5 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 1476px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol6 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 147px 8.13% 0px 0%;
}
.flexRow5__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights6 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 175px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol7 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 107px 8.13% 0px 0%;
}
.flexRow5__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights7 {
  font: $font_type_3;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  margin: 48px 0% 0px 5.06%;
}
.flexRow5__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow5__item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow5__item5 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow5__item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights8 {
  font: $font_type_3;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 175px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol8 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 59px 8.13% 0px 0%;
}
.flexRow5__item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer7 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights9 {
  font: $font_type_3;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 1089px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol9 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 99px 8.13% 0px 0%;
}
.flexRow5__item8 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer8 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle2 {
  font: $font_type_1;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
}
.highlights10 {
  font: $font_type_3;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 114px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol10 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 77px 8.13% 0px 0%;
}
.flexRow5__item9 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer9 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights11 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 386px;
  margin: 48px 0% 0px 5.06%;
}
.flexCol11 {
  @include flex-column;
  width: 91.87%;
  position: relative;
  margin: 106px 8.13% 0px 0%;
}
.flexRow5__item10 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer10 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights12 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  min-height: 201px;
  margin: 48px 0% 0px 5.06%;
}
.flexRow5__item11 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer11 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights13 {
  font: $font_type_2;
  color: $color_type_0;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 94.94%;
  position: relative;
  margin: 48px 0% 0px 5.06%;
}
.flexRow5__item12 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer12 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.flexRow5__item13 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow5__spacer13 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
