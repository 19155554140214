@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 40px/0.63 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.26 "Sora", Helvetica, Arial, serif;

.section6 {
  @include flex-column;
  height: 1140px;
  position: relative;
  @include md {
    display: none;
  }
}
.flexRow {
  display: flex;
  width: 74.41%;
  position: relative;
  flex-grow: 1;
  margin: 3px 13.54% 94px 12.05%;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 452.5px;
  margin: 129px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  width: 81.55%;
  position: relative;
  min-height: 193px;
  margin: 0px 12.6% 0px 5.86%;
}
.group {
  @include flex-column;
  height: 512px;
  position: relative;
  margin: 209px 0px 0px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 87.96%;
  position: absolute;
  top: 36px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 12.04% 0px 0%;
}
.content_box__item {
  @include flex-column;
  position: relative;
}
.icon6 {
  width: 72px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 72px;
  margin: 113px auto 0px;
}
.subtitle {
  margin: 62px 30px 55px 29px;
  font: $font_type_2;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
}
.subtitle_span0 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span1 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle_span2 {
  font: $font_type_3;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.content_box1 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 403px;
  min-width: 109px;
  right: 0px;
}
.hero_title4 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 26.61%;
  position: relative;
  flex-grow: 1;
  margin: 36px 38.99% 18px 34.4%;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 193px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 426px;
  margin: 0px 0px 162px;
}
.group1 {
  @include flex-column;
  height: 531px;
  position: relative;
}
.content_box17 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  position: absolute;
  top: 55px;
  bottom: 0px;
  left: 0px;
  right: 28px;
}
.image6 {
  width: 104px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 104px;
  margin: 74px auto 317px;
}
.content_box11 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  width: 109px;
  position: absolute;
  top: 0px;
  bottom: 422px;
  min-width: 109px;
  right: 0px;
}
.hero_title41 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_1;
  text-align: center;
  letter-spacing: 0.5px;
  width: 29px;
  position: relative;
  flex-grow: 1;
  min-width: 29px;
  margin: 43px auto 21px;
}
.hero_title11 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  position: relative;
  min-height: 198px;
  margin: 152px 39px 0px 11px;
}
