@use "utils" as *;

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgb(28, 62, 146);

$border_radius_type_0: 6px 6px 6px 6px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.26 "Roboto", Helvetica, Arial, serif;

.section4 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 74.51%;
  position: relative;
  flex-grow: 1;
  margin: 31px 10.9% 91px 14.58%;
  @include md {
    width: 95%;
  margin: 36px 5% 0px 3%;
}
}
.flexCol1 {
  @include flex-column;
  @include lg {
    margin: 0px 32px 0px 2px;
  }
  @include md {
    margin: 0px 28px 0px 2px;
  }
  @include sm {
    margin: 0px 24px 0px 2px;
  }
  @include xs {
    margin: 0px 20px 0px 2px;
  }
  @include xxs {
    margin: 0px 16px 0px 2px;
  }
  position: relative;
  margin: 0px 40px 0px 2px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, 0px);
  @include lg {
    font-size: 36px;
    text-align: left;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
  }
  @include tn {
    font-size: 20px;
  }
  position: relative;
}
.highlights {
  font: $font_type_2;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  margin: 55px 0px 0px 1px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  margin: 30px 0px 0px;
}
.flexRow {
  display: flex;
  position: relative;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.icon {
  width: 44px;
  height: min-content;
  aspect-ratio: 1;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 44px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle1 {
  margin: 9px 0px 0px;
  font: $font_type_1;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  @include md {
  min-width: 0px;
  margin: 9px 5% 0px;
  }
}
.subtitle1_span0 {
  font: $font_type_3;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle1_span1 {
  font: $font_type_4;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0.5px;
}
.rect10 {
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 35px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 87px 0px 0px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.rect101 {
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 19px 0px 0px 2px;
}
.flexCol4 {
  @include flex-column;
  position: relative;
  margin: 86px 0px 0px;
}
.flexRow__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle11 {
  margin: 9px 0px 0px;
  font: $font_type_1;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  @include md {
    min-width: 0px;
    margin: 9px 5% 0px;
    }
}
.subtitle11_span0 {
  font: $font_type_3;
  color: $color_type_1;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle11_span1 {
  @include font-face($font_type_5, $color_type_1, 0.5px);
}
.rect102 {
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 43px 0px 0px 3px;
}
.flexRow__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.flexRow__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.subtitle12 {
  font: $font_type_1;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
  margin: 9px 0px 0px;

  @include md {
  min-width: 0px;

  }
}
.rect103 {
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 285px;
  margin: 22px 0px 0px 2px;
}
