@use "utils" as *;

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgba(28, 62, 146, 0.811);
$color_type_2: rgba(28, 62, 146, 0.898);

$border_radius_type_0: 37px 37px 37px 37px;

$font_type_0: 600 32px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 0.7em/0.93 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 0.875em/1.07 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 0.78125em/1.2 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 496px;
}
.flexRow {
  display: flex;
  width: 77.64%;
  @include lg {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    column-gap: 0px;
    row-gap: 16px;
  }
  position: relative;
  flex-grow: 1;
  margin: 82px 7.78% 475px 14.58%;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 1 1 247px;
  @include lg {
    flex: 0 0 100%;
  }
}
.rect {
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  height: 303px;
  opacity: 0.89;
  position: relative;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 42px;
  @include lg {
    display: none;
  }
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 41px;
  @include lg {
    display: none;
  }
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 541px;
  @include lg {
    flex: 0 0 100%;
  }
}
.flexRow1 {
  display: flex;
  position: relative;
  flex-grow: 1;
  @include xs {
  display: block;

  }
}
.wrapper3 {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 0 1 251px;
  @include xs{
    margin-bottom: 10%;
  }
}
.title {
  margin: 108px 6px 53px 20px;
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 28px;
    text-align: center;
    margin: 108px 6px 53px 16px;
  }
  @include md {
    font-size: 24px;
    margin: 108px 6px 53px 12px;
  }
  @include sm {
    font-size: 20px;
  }
  @include tn {
    font-size: 16px;
  }
  position: relative;
  flex-grow: 1;
  min-height: 142px;
  @include xs {
    margin: 108px 6px 53px 8px;
  }
}
.title_span0 {
  font: $font_type_1;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title_span1 {
  font: $font_type_2;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title_span2 {
  font: $font_type_3;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
}
.wrapper4 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 0 1 251px;
}
.wrapper5 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
.decorator2 {
  width: calc(100% - -298px);
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: absolute;
  bottom: -52px;
  left: -190px;
  right: -108px;
}
.icon {
  width: 60px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 60px;
  margin: -100px auto 22px;
}
.icona {
  width: 60px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 60px;
  margin: 0px auto 22px;
}
.flexRow2 {
  display: flex;
  width: 515px;
  height: 142px;
  position: absolute;
  top: 190px;
  left: 227px;
}
.title1 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 28px;
    text-align: center;
  }
  @include md {
    font-size: 24px;
  }
  @include sm {
    font-size: 20px;
  }
  @include tn {
    font-size: 16px;
  }
  position: relative;
  flex: 1 1 226px;
  min-height: 142px;
}
.title1_span0 {
  font: $font_type_1;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title1_span1 {
  font: $font_type_2;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title1_span2 {
  font: $font_type_3;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.flexRow2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 64px;
}
.title2 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 28px;
    text-align: center;
  }
  @include md {
    font-size: 24px;
  }
  @include sm {
    font-size: 20px;
  }
  @include tn {
    font-size: 16px;
  }
  position: relative;
  flex: 1 1 225px;
  min-height: 142px;
}
.title2_span0 {
  font: $font_type_1;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title2_span1 {
  font: $font_type_2;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
.title2_span2 {
  font: $font_type_3;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0px;
}
