@use "utils" as *;

$color_type_0: rgb(255, 255, 255);

$border_radius_type_0: 37px 37px 37px 37px;

$font_type_0: 600 28px/1.07 "Sora", Helvetica, Arial, serif;

.group {
  @include flex-column;
  position: relative;
}
.flexRow {
  display: flex;
  width: 541px;
  height: 303px;
  position: absolute;
  top: 1235px;
  left: 207px;
}
.wrapper2 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  overflow: hidden;
  flex: 0 1 251px;
}
.decorator1 {
  width: calc(100% - -307px);
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: absolute;
  bottom: -70px;
  left: -165px;
  right: -142px;
}
.icon {
  width: 60px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 60px;
  margin: 215px auto 28px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 38px;
}
.wrapper1 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  overflow: hidden;
  flex: 0 1 252px;
}
.decorator {
  width: calc(100% - -393px);
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: absolute;
  bottom: -96px;
  left: -166px;
  right: -227px;
}
.group1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.title1 {
  @include h-center-content;
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0px;
  width: 225px;
  height: 142px;
  @include lg {
    font-size: 24px;
    text-align: center;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: absolute;
  top: 1343px;
  right: 118px;
}
.main {
  @include flex-column;
  background-color: $color_type_0;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}
.main__item {
  @include flex-column;
  position: relative;
}
