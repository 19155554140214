@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 6px 6px 6px 6px;

$font_type_0: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_2: 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_4: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_5: 0.7777777777777778em/1.8 "Sora", Helvetica, Arial, serif;
$font_type_6: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 1em/1.4 "Roboto", Helvetica, Arial, serif;

.section5 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 74.1%;
  position: relative;
  flex-grow: 1;
  margin: 111px 10.9% 0px 15%;
  @include md {
    width: 95%;
    margin: 111px 5% 0px 5%;
  }
}
.flexCol1 {
  @include flex-column;
  @include lg {
    margin: 0px 24px 0px 0px;
  }
  @include md {
    margin: 0px 20px 0px 0px;
  }
  @include sm {
    margin: 0px 16px 0px 0px;
  }
  @include tn {
    margin: 0px 12px 0px 0px;
  }
  position: relative;
  margin: 0px 31px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  @include lg {
    margin: 0px 4px 0px 0px;
  }
  position: relative;
  margin: 0px 8px 0px 0px;
}
.flexRow {
  display: flex;
  position: relative;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image2 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 25px;
  margin: 5px 0px 15px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitle1 {
  font: $font_type_1;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 808px;
  min-height: 35px;
}
.highlights {
  margin: 48px 0% 0px 5.06%;
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 1089px;
  width: 94.94%;
}
.highlights_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span1 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span2 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span3 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span4 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span5 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span6 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span7 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span8 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span9 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span10 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span11 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span12 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span13 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span14 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span15 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span16 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span17 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span18 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span19 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span20 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span21 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span22 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span23 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span24 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span25 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span26 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span27 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span28 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span29 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span30 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span31 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span32 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span33 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span34 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span35 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span36 {
  font: $font_type_4;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span37 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span38 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span39 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span40 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights_span41 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol3 {
  @include flex-column;
  @include lg {
    margin: 88px 4px 0px 0px;
  }
  position: relative;
  margin: 88px 8px 0px 0px;
}
.flexRow__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.subtitlemine{
  font: $font_type_1 !important;
  color: $color_type_2 !important;
}
.subtitle11 {
  font: $font_type_1;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  flex: 0 0 auto;
  min-width: 931px;
  min-height: 35px;
}
.highlights1 {
  margin: 48px 0% 0px 5.06%;
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 645px;
  width: 94.94%;
}
.highlights1_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span1 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span2 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span3 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span4 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span5 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span6 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span7 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span8 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span9 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span10 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span11 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span12 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span13 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span14 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span15 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span16 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span17 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span18 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span19 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span20 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span21 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span22 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span23 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span24 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span25 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span26 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span27 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span28 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span29 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span30 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span31 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span32 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span33 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights1_span34 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol4 {
  @include flex-column;
  @include lg {
    margin: 113px 4px 0px 0px;
  }
  position: relative;
  margin: 113px 8px 0px 0px;
}
.flexRow__item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.flexRow__spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights2 {
  margin: 48px 0% 0px 5.06%;
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  min-height: 944px;
  width: 94.94%;
}
.highlights2_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span1 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span2 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span3 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span4 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span5 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span6 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span7 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span8 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span9 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span10 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span11 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span12 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span13 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span14 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span15 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span16 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span17 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span18 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span19 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span20 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span21 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span22 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span23 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span24 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span25 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span26 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span27 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span28 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span29 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span30 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span31 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span32 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span33 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span34 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span35 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span36 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span37 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span38 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span39 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span40 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span41 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span42 {
  font: $font_type_5;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol5 {
  @include flex-column;
  @include lg {
    margin: 126px 0px 0px 4px;
  }
  position: relative;
  margin: 126px 0px 0px 8px;
}
.flexRow__item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 25px;
}
.image21 {
  width: 25px;
  height: min-content;
  aspect-ratio: 1.67;
  background: var(--src) center center / cover no-repeat;
  position: relative;
  min-width: 25px;
  margin: 6px 0px 14px;
}
.flexRow__spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}
.highlights3 {
  margin: 49px 0% 0px 5.06%;
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  width: 94.94%;
}
.highlights3_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span1 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span2 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span3 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span4 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span5 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span6 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span7 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span8 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span9 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span10 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span11 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span12 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span13 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span14 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span15 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span16 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span17 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span18 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span19 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span20 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span21 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span22 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span23 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span24 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span25 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span26 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span27 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span28 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span29 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span30 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span31 {
  font: $font_type_5;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights3_span32 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol6 {
  @include flex-column;
  width: 95.13%;
  position: relative;
  margin: 53px 0% 0px 4.87%;
  @include md {
  margin: 53px 0% 0px 0%;
  }
}
.flexCol7 {
  @include flex-column;
  position: relative;
}
.title {
  font: $font_type_0;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: justify;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 35px;
}
.highlights4 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    margin: 21px 20px 0px 0px;
  }
  @include lg {
    margin: 21px 32px 0px 0px;
  }
  @include md {
    margin: 21px 28px 0px 0px;
  }
  @include xxs {
    margin: 21px 16px 0px 0px;
  }
  position: relative;
  min-height: 133px;
  margin: 21px 39px 0px 0px;
}
.group {
  @include flex-column;
  position: relative;
  min-height: 1698px;
  margin: 32px 0px 0px;
}
.flexCol8 {
  @include flex-column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.highlights5 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  margin: 11px 0px 0px;
}
.highlights5_span0 {
  @include font-face($font_type_7, $color_type_2, 0.5px);
}
.flexCol9 {
  @include flex-column;
  position: absolute;
  top: 88px;
  bottom: 200px;
  left: 0px;
  right: 0px;
}
.content_box4 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
}
.highlights6 {
  @include font-face($font_type_3, $color_type_2, 0.5px);
  @include sm {
    width: 92.37%;
  }
  width: 87.19%;
  @include md {
    width: 90.08%;
  }
  @include xs {
    width: 94.16%;
  }
  @include xxs {
    width: 95.56%;
  }
  @include tn {
    width: 96.63%;
  }
  position: relative;
  flex-grow: 1;
  margin: 41px auto 20px;
}
.wrapper2 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  margin: 71px 0px 0px;
}
.highlights7 {
  @include font-face($font_type_3, $color_type_2, 0.5px);
  @include sm {
    font-size: 12px;
    text-align: left;
  }
  width: 87.19%;
  position: relative;
  flex-grow: 1;
  min-height: 136px;
  margin: 19px 8.28% 20px 4.53%;
}
.wrapper21 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  margin: 81px 0px 0px;
}
.highlights8 {
  margin: 19px 8.28% 20px 4.53%;
  @include font-face($font_type_3, $color_type_2, 0.5px);
  @include sm {
    font-size: 12px;
    text-align: left;
  }
  position: relative;
  flex-grow: 1;
  min-height: 136px;
  width: 87.19%;
}
.highlights8_span0 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span1 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span2 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span3 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span4 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span5 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span6 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span7 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span8 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span9 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span10 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span11 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span12 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span13 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span14 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span15 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span16 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span17 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span18 {
  font: $font_type_4;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights8_span19 {
  font: $font_type_6;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.wrapper22 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  margin: 67px 0px 0px;
}
