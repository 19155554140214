@use "utils" as *;

$color_type_0: rgb(230, 186, 70);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 10px 10px 10px 10px;
$border_radius_type_1: 6px 6px 6px 6px;

$font_type_0: 700 25px/1.28 "Sora", Helvetica, Arial, serif;
$font_type_1: 600 18px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 15px/1.68 "Sora", Helvetica, Arial, serif;
$font_type_3: 15px/1.68 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 3454px;
  @include xmudder{
    display: none;
  }
}
.group {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 3248px;
  margin: 37px 26px 169px 23px;
}
.medium_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_2, 0px);
  position: absolute;
  top: 0px;
  bottom: 3184px;
  left: 36px;
  right: 13px;
}
.flexCol {
  @include flex-column;
  position: absolute;
  top: 104px;
  bottom: 2640px;
  left: 31px;
  right: 29px;
}
.highlights {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_2, 0.5px);
  position: relative;
  min-height: 206px;
}
.rect2 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 257px;
  margin: 41px 2px 0px 0px;
}
.flexCol1 {
  @include flex-column;
  position: absolute;
  top: 633px;
  bottom: 2228px;
  left: 31px;
  right: 31px;
}
.highlights1 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 76px;
}
.rect21 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 257px;
  margin: 54px 0px 0px;
}
.flexCol2 {
  @include flex-column;
  position: absolute;
  top: 1036px;
  bottom: 1754px;
  left: 31px;
  right: 29px;
}
.highlights2 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 163px;
  margin: 0px 2px 0px 0px;
}
.rect22 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 257px;
  margin: 38px 0px 0px 2px;
}
.flexCol3 {
  @include flex-column;
  position: absolute;
  top: 1515px;
  bottom: 1324px;
  left: 31px;
  right: 29px;
}
.highlights3 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 0px 2px 0px 0px;
}
.rect23 {
  background-color: $color_type_1;
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(95, 111, 161);
  outline-offset: -1px;
  position: relative;
  min-height: 257px;
  margin: 40px 0px 0px 2px;
}
.flexCol4 {
  @include flex-column;
  position: absolute;
  top: 1943px;
  bottom: 221px;
  left: 31px;
  right: 29px;
}
.highlights4 {
  font: $font_type_2;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 184px;
}
.highlights5 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 857px;
  margin: 43px 2px 0px 0px;
}
.group1 {
  @include flex-column;
  width: 51.51%;
  position: absolute;
  top: 3206px;
  bottom: 1px;
  min-height: 41px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 0%;
  border-radius: 10px 10px 10px 10px;
    outline: 2px solid rgb(28, 62, 146);
    outline-offset: -2px;
}
.highlights11 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: absolute;
  top: 10px;
  bottom: 9px;
  min-height: 22px;
  left: 0px;
  right: 9px;
}
.rect3 {
  border-radius: $border_radius_type_0;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  min-height: 41px;
  left: 0px;
  right: 0px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 50%;
  position: absolute;
  top: 3206px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 54.63%;
}
.flexRow {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 11px 2px 9px 16px;
}
.flexRow__item {
  @include flex-column;
  position: relative;
  flex: 0 1 22px;
}
.image2 {
  width: 22px;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 22px;
  margin: -20% 20% 30% -20%;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.highlights21 {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex: 0 0 auto;
  min-width: 95px;
}
