@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section6 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin-top: 20%;
}
.flexCol {
  @include flex-column;
  width: 75.42%;
  @include lg {
    width: 80.36%;
  }
  @include md {
    width: 94.51%;
    margin: 53px 5% 22px 5%;
}
  @include sm {
    width: 94.91%;
  }
  @include xs {
    width: 90.65%;
  }
  @include xxs {
    width: 94.82%;
  }
  @include tn {
    width: 94.52%;
  }
  position: relative;
  flex-grow: 1;
  margin: 53px auto 22px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, 0px);
  @include lg {
    margin: 0px 8px;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    margin: 0px 4px;
  }
  @include tn {
    font-size: 20px;
  }
  position: relative;
  margin: 0px 13px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  @include lg {
    margin: 55px 28px 0px 0px;
  }
  @include md {
    margin: 55px 24px 0px 0px;
  }
  @include sm {
    margin: 55px 20px 0px 0px;
  }
  @include xs {
    margin: 55px 16px 0px 0px;
  }
  position: relative;
  margin: 55px 35px 0px 0px;
}
.subtitle {
  @include font-face($font_type_2, $color_type_1, 0.5px);
  @include lg {
    font-size: 16px;
    text-align: left;
  }
  width: 83.06%;
  position: relative;
  flex-grow: 1;
  min-height: 219px;
  margin: 54px 8.94% 24px 7.99%;
}
.group {
  @include flex-column;
  position: relative;
  min-height: 544px;
  margin: 64px 0px 0px 6px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol1 {
  @include flex-column;
  width: 77.65%;
  position: relative;
  flex-grow: 1;
  margin: 79px 14.45% 53px 7.9%;
}
.medium_title {
  @include font-face($font_type_1, $color_type_1, 0.5px);
  @include md {
    font-size: 20px;
    text-align: left;
  }
  @include sm {
    font-size: 16px;
  }
  width: 83.75%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.25% 0px 0%;
}
.subtitle1 {
  font: $font_type_2;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
  min-height: 280px;
  margin: 21px 0px 0px 2px;
}
.image1 {
  width: 147px;
  height: min-content;
  aspect-ratio: 1;
  background: var(--src) center center / cover no-repeat;
  position: absolute;
  top: 0px;
  bottom: 397px;
  min-width: 147px;
  right: 0px;
}
