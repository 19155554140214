@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 54px 54px 54px 54px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 28px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_4: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_5: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_6: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 700 1em/1.43 "Sora", Helvetica, Arial, serif;
$font_type_8: 700 1em/1.25 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 3431px;
}
.flexCol {
  @include flex-column;
  width: 75.56%;
  position: relative;
  flex-grow: 1;
  margin: 50px 9.86% 277px 14.58%;
  @include md {
    width: 90.56%;
    margin: 50px 5% 277px 5%;
  }
}
.flexCol1 {
  @include flex-column;
  width: 94.76%;
  position: relative;
  margin: 0px 5.24% 0px 0%;
}
.title {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
}
.highlights {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 148px;
  margin: 35px 0px 0px 1px;
}
.flexCol2 {
  @include flex-column;
  width: 94.76%;
  position: relative;
  margin: 77px 5.24% 0px 0%;
}
.highlights1 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 165px;
  margin: 35px 0px 0px 1px;
}
.flexCol3 {
  @include flex-column;
  width: 94.76%;
  position: relative;
  margin: 52px 5.15% 0px 0.09%;
}
.highlights2 {
  margin: 44px 0px 0px 1px;
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
}
.highlights2_span0 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span1 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights2_span2 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol4 {
  @include flex-column;
  width: 94.76%;
  position: relative;
  margin: 20px 5.24% 0px 0%;
}
.highlights3 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 165px;
  margin: 44px 0px 0px 1px;
}
.title1 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 77px 2px 0px;
}
.flexCol5 {
  @include flex-column;
  width: 94.85%;
  position: relative;
  margin: 84px 5.15% 0px 0%;
}
.highlights4 {
  font: $font_type_4;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  margin: 44px 0px 0px 2px;
}
.title2 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 52px 0px 0px;
}
.title3 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 64px 0px 0px;
}
.title4 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 66px 2px 0px;
}
.title5 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 70px 2px 0px;
}
.title6 {
  @include v-center-content;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 72px 0px 0px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_1;
  width: 72.89%;
  position: relative;
  margin: 98px 20.86% 0px 6.25%;
  @include md {
    width: 100%;
    position: relative;
    margin: 98px 0% 0px 0%;
  }
}
.flexCol6 {
  @include flex-column;
  width: 81.21%;
  position: relative;
  flex-grow: 1;
  margin: 68px auto 53px;
  @include md {
    width: 100%;
    position: relative;
    flex-grow: 1;
    margin: 20% auto 10%;
  }
}
.title11 {
  font: $font_type_1;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 161px;
  @include md {
    letter-spacing: 0.5px;
    position: relative;
    min-height: 161px;
  }
}
.title11_span0 {
  font: $font_type_7;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span1 {
  font: $font_type_7;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title11_span2 {
  font: $font_type_7;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 25.16%;
  position: relative;
  margin: 39px auto 0px;
  @include md {
    width: 50%;
    position: relative;
    margin: 15% auto 0px;
  }
}

.box1 {
  @include flex-column;
  background-color: $color_type_1;
  border-radius: $border_radius_type_0;
  width: 15.16%;
  position: relative;
  margin: 39px auto 0px;
  @include md {
    width: 50%;
  }
}
.subtitle1 {
  @include font-face($font_type_3, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 25px;
  @include md {
  margin: 15px 25%;
  }
}

.subtitle2 {
  @include font-face($font_type_3, $color_type_2, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 25px;
  @include md {
    margin: 15px 30%;
    }
}
.hero_title2 {
  margin: 307px 0px 0px;
  @include font-face($font_type_0, $color_type_0, 0px);
  position: relative;
  min-height: 309px;
  @include md {
  margin: 50% 0px 0px;
    width: 100%;
  }
}
.hero_title2_span0 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title2_span1 {
  font: $font_type_8;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0px;
}
.hero_title2_span2 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0px;
}
