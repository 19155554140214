@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 20px 20px 20px 20px;
$border_radius_type_2: 54px 54px 54px 54px;

$font_type_0: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_3: 700 22px/1.14 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 1em/1.14 "Sora", Helvetica, Arial, serif;
$font_type_5: 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_6: 600 0.8181818181818182em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 0.8181818181818182em/1.4 "Roboto", Helvetica, Arial, serif;
$font_type_8: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section8 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 76.39%;
  position: relative;
  flex-grow: 1;
  margin: 60px 9.44% 549px 14.17%;
  @include md {
  width: 90%;
  margin: 60px 5% 549px 5%;

  }
}
.flexCol1 {
  @include flex-column;
  @include lg {
    margin: 0px 4px 0px 9px;
  }
  @include md {
    margin: 0px 4px;
  }
  position: relative;
  margin: 0px 8px 0px 9px;
}
.title {
  font: $font_type_1;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: justify;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  width: 93.72%;
  position: relative;
  min-height: 35px;
  margin: 0px 6.28% 0px 0%;
}
.subtitle3 {
  margin: 39px 0px 0px;
  font: $font_type_3;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include md {
    font-size: 16px;
    text-align: justify;
  }
  position: relative;
}
.subtitle3_span0 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span1 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span2 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span3 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span4 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span5 {
  @include font-face($font_type_7, $color_type_4, 0.5px);
}
.subtitle3_span6 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span7 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span8 {
  @include font-face($font_type_7, $color_type_4, 0.5px);
}
.subtitle3_span9 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span10 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span11 {
  @include font-face($font_type_7, $color_type_4, 0.5px);
}
.subtitle3_span12 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span13 {
  font: $font_type_5;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span14 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span15 {
  @include font-face($font_type_7, $color_type_4, 0.5px);
}
.subtitle3_span16 {
  font: $font_type_4;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span17 {
  font: $font_type_6;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle3_span18 {
  @include font-face($font_type_7, $color_type_4, 0.5px);
}
.flexCol2 {
  @include flex-column;
  position: relative;
  margin: 6px 0px 0px;
}
.rect {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
}
.rect1 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 34px 0px 0px;
}
.rect11 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 22px 0px 0px;
}
.rect12 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 18px 0px 0px;
}
.rect2 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 75px 0px 0px;
}
.rect13 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 35px 0px 0px;
}
.rect3 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 67px 0px 0px;
}
.rect14 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 20px 0px 0px;
}
.rect15 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 21px 0px 0px;
}
.rect16 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 17px 0px 0px;
}
.rect17 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 67px 0px 0px;
}
.rect4 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 17px 0px 0px;
}
.rect5 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(253, 194, 12);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 77px 0px 0px;
}
.rect18 {
  background-color: $color_type_3;
  border-radius: $border_radius_type_1;
  outline: 2px solid rgb(28, 62, 146);
  outline-offset: -2px;
  position: relative;
  min-height: 57px;
  margin: 36px 0px 0px;
}
.content_box11 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_2;
  width: 72.09%;
  position: relative;
  margin: 257px 17% 0px 10.91%;
  @include md {
  width: 90%;
  margin: 257px 5% 0px 5%;

  }
}
.flexCol3 {
  @include flex-column;
  width: 81.21%;
  @include sm {
    width: 85.22%;
  }
  @include xs {
    width: 88.49%;
  }
  @include xxs {
    width: 91.11%;
  }
  @include tn {
    width: 93.18%;
  }
  position: relative;
  flex-grow: 1;
  margin: 68px auto 53px;
}
.title1 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: center;
  }
  @include md {
    font-size: 20px;
  min-height: 100px;
}
  @include xs {
    font-size: 16px;
  }
  position: relative;
  min-height: 161px;
}
.title1_span0 {
  font: $font_type_8;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title1_span1 {
  font: $font_type_8;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title1_span2 {
  font: $font_type_8;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 43%;
  position: relative;
  margin: 39px auto 0px;
  @include md {
  width: 60%;
  margin: 0px auto 0px;

  }
}
.group {
  @include flex-column;
  @include lg {
    margin: 15px auto;
  }
  @include md {
    margin: 15px auto;
  }
  @include sm {
    margin: 15px auto;
  }
  @include xxs {
    margin: 15px auto;
  }
  position: relative;
  flex-grow: 1;
  margin: 15px auto;
}
.subtitle2 {
  @include font-face($font_type_2, $color_type_1, 0px);
  @include md {
    margin: 0px 4px;
  }
  position: relative;
  flex-grow: 1;
  margin: 0px 10px;
}
