@use "utils" as *;

$color_type_0: rgb(240, 239, 239);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(28, 62, 146);

$border_radius_type_0: 44px 44px 44px 44px;

$font_type_0: 600 48px/0.93 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 26px/0.96 "Sora", Helvetica, Arial, serif;
$font_type_2: 700 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_3: 400 20px/1.26 "Sora", Helvetica, Arial, serif;

.section1 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexRow {
  @include v-center-content;
  width: 93.06%;
  position: relative;
  margin: 59px auto 0px;
}
.flexRow__cell {
  @include flex-column;
  position: relative;
  flex: 0 1 78px;
}
.image2 {
  width: 78px;
  height: auto;
  aspect-ratio: 1.9;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 78px;
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 1179px;
}
.flexRow__cell1 {
  @include flex-column;
  position: relative;
  flex: 0 1 45px;
}
.image1 {
  width: 45px;
  height: auto;
  aspect-ratio: 1.32;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 45px;
  margin: 3px 0px 4px;
}
.content_box2 {
  @include flex-column;
  background-color: $color_type_2;
  position: relative;
  margin: 0px 0px 0px;
}
.flexRow1 {
  @include v-center-content;
  width: 77.22%;
  position: relative;
  flex-grow: 1;
  margin: 70px auto 150px;
}
.hero_title {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, -0.5px);
  position: relative;
  flex: 0 1 478px;
  min-height: 173px;
  margin: 0px 0px 33px;
}
.flexRow1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 191px;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 115px;
  margin: 30px 0px 0px;
}
.flexCol__cell {
  @include flex-column;
  position: relative;
}
.image {
  width: 82px;
  height: auto;
  aspect-ratio: 1.09;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: relative;
  min-width: 82px;
  margin: 0px 33px 0px 0px;
}
.medium_title {
  @include font-face($font_type_1, $color_type_1, 0.5px);
  position: relative;
  min-height: 50px;
  margin: 51px 0px 0px;
}
.flexRow1__spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 130px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
  flex: 0 1 178px;
  margin: 30px 0px 0px;
}
.flexCol1__cell {
  @include flex-column;
  position: relative;
}
.image3 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.09;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.content_box {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 72.99%;
  position: relative;
  margin: 21px auto 0px;
}
.subtitle {
  @include font-face($font_type_2, $color_type_2, 0.5px);
  width: 83.06%;
  position: relative;
  flex-grow: 1;
  margin: 60px 9.04% 22px 7.9%;
}
.group {
  @include flex-column;
  width: 75%;
  position: relative;
  min-height: 544px;
  margin: 16px 10.9% 43px 14.1%;
}
.content_box1 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  width: 96.11%;
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0px 3.89% 0px 0%;
}
.flexCol2 {
  @include flex-column;
  width: 77.65%;
  position: relative;
  flex-grow: 1;
  margin: 79px 14.45% 53px 7.9%;
}
.medium_title1 {
  @include font-face($font_type_1, $color_type_2, 0.5px);
  width: 83.75%;
  position: relative;
  min-height: 43px;
  margin: 0px 16.25% 0px 0%;
}
.subtitle1 {
  font: $font_type_3;
  color: $color_type_2;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 280px;
  margin: 21px 0px 0px 2px;
}
.image31 {
  width: 13.61%;
  height: auto;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0px;
  bottom: 397px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 86.39%;
}
