@use "utils" as *;

$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(17, 74, 160);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);

$border_radius_type_0: 17px 17px 17px 17px;
$border_radius_type_1: 22px 22px 22px 22px;
$border_radius_type_2: 54px 54px 54px 54px;

$font_type_0: 700 28px/1.43 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 28px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_2: 600 28px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_3: 600 28px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_4: 700 22px/1.27 "Sora", Helvetica, Arial, serif;
$font_type_5: 600 20px/1.26 "Sora", Helvetica, Arial, serif;
$font_type_6: 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_7: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_8: 600 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_9: 1em/1.26 "Sora", Helvetica, Arial, serif;
$font_type_10: 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_11: 0.7777777777777778em/1.8 "Sora", Helvetica, Arial, serif;
$font_type_12: 600 1em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_13: 0.7em/1.8 "Sora", Helvetica, Arial, serif;
$font_type_14: 0.9em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_15: 600 0.9em/1.4 "Sora", Helvetica, Arial, serif;
$font_type_16: 700 1em/1.43 "Sora", Helvetica, Arial, serif;

.section2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 75.83%;
  position: relative;
  flex-grow: 1;
  margin: 82px 11.25% 334px 12.92%;
}
.flexCol1 {
  @include lg {
    margin: 0px 28px 0px 20px;
  }
  @include md {
    margin: 0px 24px 0px 16px;
  }
  @include sm {
    margin: 0px 20px 0px 12px;
  }
  @include xxs {
    margin: 0px 16px 0px 12px;
  }
  @include tn {
    margin: 0px 16px 0px 8px;
  }
  @include flex-column;
  position: relative;
  margin: 0px 36px 0px 24px;
}
.flexCol2 {
  @include flex-column;
  position: relative;
}
.title {
  @include lg {
    font-size: 24px;
    text-align: left;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
}
.highlights {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 263px;
  margin: 62px 0px 0px 1px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 102px 0px 0px 1px;
}
.highlights1 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_7;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 192px;
  margin: 62px 0px 0px 1px;
}
.flexCol4 {
  @include flex-column;
  width: 93.51%;
  position: relative;
  margin: 47px 6.3% 0px 0.19%;
}
.highlights2 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 176px;
  margin: 23px 0px 0px;
}
.flexCol__cell {
  @include flex-column;
  position: relative;
}
.group {
  @include lg {
    margin: 946px 28px 0px 0px;
  }
  @include md {
    margin: 946px 24px 0px 0px;
  }
  @include sm {
    margin: 946px 20px 0px 0px;
  }
  @include xs {
    margin: 946px 16px 0px 0px;
  }
  @include tn {
    margin: 946px 12px 0px 0px;
  }
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 382px;
  margin: 946px 34px 0px 0px;
}
.highlights3 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 270px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle1 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 318px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.image2 {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.67;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
}
.rect4 {
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 300px;
  min-height: 82px;
  left: 0px;
  right: 0px;
}
.group1 {
  @include lg {
    margin: 48px 28px 0px 0px;
  }
  @include md {
    margin: 48px 24px 0px 0px;
  }
  @include sm {
    margin: 48px 20px 0px 0px;
  }
  @include xs {
    margin: 48px 16px 0px 0px;
  }
  @include tn {
    margin: 48px 12px 0px 0px;
  }
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 382px;
  margin: 48px 34px 0px 0px;
}
.highlights4 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_7;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.25%;
  position: absolute;
  top: 112px;
  bottom: 0px;
  min-height: 270px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.75%;
}
.subtitle11 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
}
.subtitle11_box {
  @include lg {
    align-items: flex-start;
  }
  width: 76.37%;
  position: absolute;
  top: 29px;
  bottom: 277px;
  left: 0px;
  right: 0px;
  margin: 0px 15.88% 0px 7.75%;
}
.subtitle11_span0 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle11_span1 {
  font: $font_type_9;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.group2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 370px;
  margin: 77px 0px 0px;
}
.highlights5 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_7;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.49%;
  position: absolute;
  top: 100px;
  bottom: 0px;
  min-height: 270px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.51%;
}
.subtitle12 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 73.99%;
  position: absolute;
  top: 29px;
  bottom: 306px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 18.5% 0px 7.51%;
}
.rect41 {
  @include lg {
    right: 28px;
  }
  @include md {
    right: 24px;
  }
  @include sm {
    right: 20px;
  }
  @include xs {
    right: 16px;
  }
  @include tn {
    right: 12px;
  }
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 288px;
  min-height: 82px;
  left: 0px;
  right: 34px;
}
.group3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 640px;
  margin: 77px 0px 0px;
}
.highlights6 {
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
}
.highlights6_box {
  @include sm {
    align-items: flex-start;
  }
  position: relative;
  min-height: 299px;
  margin: 46px 0px 0px;
}
.highlights6_span0 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span1 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span2 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span3 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span4 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span5 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span6 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span7 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span8 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span9 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span10 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span11 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span12 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span13 {
  font: $font_type_12;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span14 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span15 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span16 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle13 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 73.99%;
  position: absolute;
  top: 29px;
  bottom: 576px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 18.5% 0px 7.51%;
}
.rect42 {
  @include lg {
    right: 28px;
  }
  @include md {
    right: 24px;
  }
  @include sm {
    right: 20px;
  }
  @include xs {
    right: 16px;
  }
  @include tn {
    right: 12px;
  }
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 558px;
  min-height: 82px;
  left: 0px;
  right: 34px;
}
.group4 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 204px;
  margin: 77px 0px 0px;
}
.highlights7 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.49%;
  position: absolute;
  top: 100px;
  bottom: 0px;
  min-height: 104px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.51%;
}
.subtitle14 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 73.99%;
  position: absolute;
  top: 29px;
  bottom: 140px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 18.5% 0px 7.51%;
}
.rect43 {
  @include lg {
    right: 28px;
  }
  @include md {
    right: 24px;
  }
  @include sm {
    right: 20px;
  }
  @include xs {
    right: 16px;
  }
  @include tn {
    right: 12px;
  }
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 122px;
  min-height: 82px;
  left: 0px;
  right: 34px;
}
.group5 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 318px;
  margin: 70px 0px 0px;
}
.highlights8 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.49%;
  position: absolute;
  top: 100px;
  bottom: 0px;
  min-height: 218px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.51%;
}
.subtitle15 {
  @include lg {
    font-size: 16px;
  }
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 73.99%;
  position: absolute;
  top: 29px;
  bottom: 254px;
  min-height: 35px;
  left: 0px;
  right: 0px;
  margin: 0px 18.5% 0px 7.51%;
}
.rect44 {
  @include lg {
    right: 28px;
  }
  @include md {
    right: 24px;
  }
  @include sm {
    right: 20px;
  }
  @include xs {
    right: 16px;
  }
  @include tn {
    right: 12px;
  }
  border-radius: $border_radius_type_1;
  outline: 1px solid rgb(253, 194, 12);
  outline-offset: -1px;
  position: absolute;
  top: 0px;
  bottom: 236px;
  min-height: 82px;
  left: 0px;
  right: 34px;
}
.group6 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 318px;
  margin: 75px 0px 0px;
}
.highlights9 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  width: 92.49%;
  position: absolute;
  top: 100px;
  bottom: 0px;
  min-height: 218px;
  left: 0px;
  right: 0px;
  margin: 0px 0% 0px 7.51%;
}
.flexCol5 {
  @include flex-column;
  width: 92.95%;
  position: relative;
  margin: 89px 1.19% 0px 5.86%;
}
.title1 {
  @include lg {
    font-size: 24px;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  font: $font_type_3;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 35px;
}
.highlights6_span17 {
  font: $font_type_11;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span18 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span19 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span20 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span21 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span22 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span23 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span24 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span25 {
  font: $font_type_11;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.highlights6_span26 {
  font: $font_type_10;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16 {
  font: $font_type_5;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 16px;
    text-align: justify;
  }
}
.subtitle16_box {
  @include lg {
    align-items: flex-start;
  }
  position: relative;
  min-height: 160px;
  margin: 199px 0px 0px;
}
.subtitle16_span0 {
  font: $font_type_8;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span1 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span2 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span3 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span4 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span5 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span6 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span7 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span8 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span9 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span10 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span11 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span12 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span13 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span14 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span15 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span16 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span17 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span18 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span19 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span20 {
  font: $font_type_15;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span21 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span22 {
  font: $font_type_13;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span23 {
  font: $font_type_13;
  color: $color_type_0;
  font-style: normal;
  letter-spacing: 0.5px;
}
.subtitle16_span24 {
  font: $font_type_14;
  color: $color_type_4;
  font-style: normal;
  letter-spacing: 0.5px;
}
.flexCol6 {
  @include flex-column;
  width: 94.32%;
  position: relative;
  margin: 25px 1.28% 0px 4.4%;
}
.highlights10 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 687px;
  margin: 51px 0px 0px;
}
.flexCol7 {
  @include flex-column;
  width: 89.38%;
  position: relative;
  margin: 20.64px 7.23% 0px 3.39%;
}
.title2 {
  @include lg {
    font-size: 24px;
    text-align: left;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
  @include sm {
    margin: 0px 4px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_2, $color_type_4, 0px);
  position: relative;
  margin: 0px 11px;
}
.flexCol8 {
  @include flex-column;
  width: 89.38%;
  position: relative;
  margin: 220px 8.88% 0px 1.74%;
}
.highlights11 {
  @include sm {
    font-size: 12px;
  }
  font: $font_type_6;
  color: $color_type_4;
  text-align: justify;
  letter-spacing: 0.5px;
  position: relative;
  min-height: 299px;
  margin: 46px 0px 0px;
}
.title3 {
  @include lg {
    font-size: 24px;
    text-align: left;
    margin: 48px 28px 0px 0px;
  }
  @include md {
    font-size: 20px;
    margin: 48px 24px 0px 0px;
  }
  @include xs {
    font-size: 16px;
  }
  @include sm {
    margin: 48px 20px 0px 0px;
  }
  @include xxs {
    margin: 48px 16px 0px 0px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 48px 36px 0px 0px;
}
.title4 {
  @include lg {
    font-size: 24px;
    text-align: left;
    margin: 85px 28px 0px 0px;
  }
  @include md {
    font-size: 20px;
    margin: 85px 24px 0px 0px;
  }
  @include xs {
    font-size: 16px;
  }
  @include sm {
    margin: 85px 20px 0px 0px;
  }
  @include xxs {
    margin: 85px 16px 0px 0px;
  }
  display: flex;
  align-items: center;
  @include font-face($font_type_1, $color_type_4, 0px);
  position: relative;
  margin: 85px 36px 0px 0px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_2;
  width: 72.62%;
  position: relative;
  margin: 393px 18.86% 0px 8.52%;
}
.flexCol9 {
  @include sm {
    width: 85.22%;
  }
  @include xs {
    width: 88.49%;
  }
  @include xxs {
    width: 91.11%;
  }
  @include tn {
    width: 93.18%;
  }
  @include flex-column;
  width: 81.21%;
  position: relative;
  flex-grow: 1;
  margin: 68px auto 53px;
}
.title21 {
  font: $font_type_0;
  color: $color_type_0;
  text-align: center;
  letter-spacing: 0.5px;
  @include lg {
    font-size: 24px;
    text-align: center;
  }
  @include md {
    font-size: 20px;
  }
  @include xs {
    font-size: 16px;
  }
}
.title21_box {
  @include lg {
    align-items: flex-start;
    justify-content: center;
  }
  @include md {
    align-items: flex-start;
    justify-content: center;
  }
  @include xs {
    align-items: flex-start;
    justify-content: center;
  }
  display: flex;
  justify-content: center;
  position: relative;
}
.title21_span0 {
  font: $font_type_16;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title21_span1 {
  font: $font_type_16;
  color: $color_type_2;
  font-style: normal;
  letter-spacing: 0.5px;
}
.title21_span2 {
  font: $font_type_16;
  color: $color_type_3;
  font-style: normal;
  letter-spacing: 0.5px;
}
.box {
  @include flex-column;
  background-color: $color_type_2;
  border-radius: $border_radius_type_0;
  width: 25.16%;
  position: relative;
  margin: 39px auto 0px;
}
.subtitle2 {
  @include md {
    font-size: 16px;
    text-align: left;
    margin: 15px 28px;
  }
  @include lg {
    margin: 15px 32px;
  }
  @include sm {
    margin: 15px 20px;
  }
  @include xxs {
    margin: 15px 16px;
  }
  @include font-face($font_type_4, $color_type_1, 0px);
  position: relative;
  flex-grow: 1;
  margin: 15px 39px;
}
