@use "utils" as *;

$color_type_0: rgb(255, 255, 255);
$color_type_1: rgb(28, 62, 146);
$color_type_2: rgb(46, 67, 132);

$border_radius_type_0: 17px 17px 17px 17px;

$font_type_0: 700 40px/1.25 "Sora", Helvetica, Arial, serif;
$font_type_1: 400 18px/1.4 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 16px/1.5 "Sora", Helvetica, Arial, serif;

.section3 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  width: 71.67%;
  @include lg {
    width: 77.13%;
  }
  @include md {
    width: 81.81%;
  }
  @include sm {
    width: 85.71%;
  }
  @include xs {
    width: 88.89%;
  }
  @include xxs {
    width: 91.43%;
  }
  @include tn {
    width: 93.43%;
  }
  position: relative;
  flex-grow: 1;
  margin: 76px auto 31px;
}
.flexCol1 {
  @include flex-column;
  position: relative;
}
.flexCol2 {
  @include flex-column;
  position: relative;
  margin: 0px 1px 0px 0px;
}
.hero_title1 {
  @include v-center-content;
  @include font-face($font_type_0, $color_type_1, 0px);
  @include lg {
    font-size: 36px;
    text-align: left;
  }
  @include md {
    font-size: 32px;
  }
  @include sm {
    font-size: 28px;
  }
  @include xs {
    font-size: 24px;
  }
  @include tn {
    font-size: 20px;
  }
  position: relative;
}
.highlights {
  font: $font_type_1;
  color: $color_type_1;
  text-align: justify;
  letter-spacing: 0.5px;
  @include sm {
    font-size: 12px;
    text-align: justify;
  }
  position: relative;
  margin: 55px 0px 0px 1px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 29px 0px 0px 1px;
}
.content_box3 {
  @include flex-column;
  background-color: $color_type_0;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(46, 67, 132);
  outline-offset: -1px;
  width: 59.69%;
  position: relative;
  margin: 51px 40.21% 0px 0.1%;
}
.highlights2 {
  @include font-face($font_type_2, $color_type_2, 0px);
  @include lg {
    font-size: 12px;
    text-align: left;
  }
  width: 53.25%;
  position: relative;
  flex-grow: 1;
  margin: 10px 42.05% 8px 4.71%;
}
