@use "utils" as *;

$color_type_0: rgb(107, 114, 128);
$color_type_1: rgb(209, 213, 219);
$color_type_2: rgb(253, 194, 12);
$color_type_3: rgb(255, 255, 255);
$color_type_4: rgb(28, 62, 146);
$color_type_5: rgb(55, 65, 81);

$border_radius_type_0: 6px 6px 6px 6px;

$font_type_0: 700 38px/0.75 "Sora", Helvetica, Arial, serif;
$font_type_1: 700 22px/0.9 "Sora", Helvetica, Arial, serif;
$font_type_2: 400 14px/1.42 "Sora", Helvetica, Arial, serif;

.root {
  @include flex-column;
  margin-top: 4%;
  position: relative;
  width: 40vw;
  height: auto;
  align-self: center;
  @include md {
    display: none;
  }
}
.builtlogo{
  width:7vw;
  align-self: center;
  margin-bottom: 5%;
  margin-right: 5%;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 69px 5px;
}
.hero_title {
  @include v-center-content;
  justify-content: center;
  font: $font_type_0;
  color: $color_type_4;
  text-align: left;
  letter-spacing: 0px;
  position: relative;
  min-height: 71px;
  line-height: 100%;
}
.flexCol1 {
  @include flex-column;
  width: 100%;
  position: relative;
  margin: 27px 0% 0px 0%;
}
.flexCol2 {
  @include flex-column;
  position: relative;
}
.text {
  @include font-face($font_type_2, $color_type_5, 0px);
  position: relative;
}
.rect {
  background-color: $color_type_3;
  border-radius: $border_radius_type_0;
  outline: 1px solid rgb(209, 213, 219);
  outline-offset: -1px;
  height: 38px;
  position: relative;
  margin: 5px 0px 0px;
}
.flexCol3 {
  @include flex-column;
  position: relative;
  margin: 11px 0px 0px;
}
.content_box {
  @include flex-column;
  background-color: $color_type_4;
  border-radius: $border_radius_type_0;
  position: relative;
  margin: 76px 0px 0px;
}
.subtitle {
  @include h-center-content;
  font: $font_type_1;
  color: $color_type_2;
  text-align: center;
  letter-spacing: 0px;
  width: 128px;
  position: relative;
  flex-grow: 1;
  min-width: 128px;
  margin: 8px auto 10px;
}
.line {
  background-color: $color_type_1;
  width: 77.74%;
  height: 1px;
  position: relative;
  margin: 31px 7.53% 0px 7.53%;
}
.text1 {
  @include font-face($font_type_2, $color_type_0, 0px);
  width: 100%;
  position: relative;
  margin: 21px auto 0px;
  text-align: center;
}
